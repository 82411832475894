import _ from 'lodash'
import * as XLSX from 'xlsx'

// utils
import {
  displayTime,
  CSVArrayToObj,
  getFileNameAndExt,
  getBase64EncodedFiles,
} from 'helpers/utils'
import { Payload } from 'types/common'
import { Timezone } from 'types/datetime'
import { OptimizationSettings } from '.'

export const getFormattedDateRange = (
  optimizationSettings: OptimizationSettings,
  timezone: Timezone
):
  | {
      startDate: string
      endDate: string
    }
  | undefined => {
  const { startDate, endDate } = optimizationSettings

  if (!startDate || !endDate) return undefined
  return {
    startDate: displayTime({
      datetime: startDate,
      timezone,
      addFromNow: false,
      timeFormat: 'YYYY-MM-DD',
      displayLabel: false,
    }),
    endDate: displayTime({
      datetime: endDate,
      timezone,
      addFromNow: false,
      timeFormat: 'YYYY-MM-DD',
      displayLabel: false,
    }),
  }
}

export const getUploadPayload = async ({
  settings,
  uploadFiles,
  environment,
  timezone,
  bucket,
  path,
  fileName,
  folderName,
}: {
  uploadFiles: File
  settings: Payload
  environment: string
  timezone: Timezone
  bucket: string
  path: string
  fileName?: string
  folderName?: string
}): Promise<Payload> => {
  const filesBase64 = await getBase64EncodedFiles(uploadFiles)
  const UPLOAD_PATH_PREFIX = folderName ? `${path}${folderName}/` : path

  const Bucket = `${environment}-${bucket}`
  const module = 'awsS3'
  const operation = 'putObject'

  const dateRange = getFormattedDateRange(settings, timezone)

  const newConfigFilename = fileName
    ? `${_.first(getFileNameAndExt(fileName))}`
    : 'config'

  const configFile = {
    module,
    params: {
      data: {
        inputTemplate: {
          Bucket,
          Key: `${UPLOAD_PATH_PREFIX}${newConfigFilename}.json`,
          Body: {
            ...settings,
            ...dateRange,
          },
        },
      },
      operation,
      inputConverter: [
        '$data.inputTemplate',
        "$input ~> | $ | { 'Body': $string(Body) } |",
      ],
      outputConverter: "{ 'firstResponse': $s3Response }",
    },
  }

  const files = _.map(filesBase64, (file, originalFileName) => ({
    module,
    params: {
      data: {
        inputTemplate: {
          Bucket,
          Key: fileName
            ? `${UPLOAD_PATH_PREFIX}${fileName}`
            : `${UPLOAD_PATH_PREFIX}${originalFileName}`,
          Body: file,
        },
      },
      operation,
      inputConverter: [
        '$data.inputTemplate',
        "$input ~> | $ | { 'Body': $bufferFromBase64(Body) } |",
      ],
      outputConverter: "$row ~> | $ | { 'secondResponse': $s3Response } |",
    },
  }))

  return {
    dataSource: {
      source: 'inline',
      rows: [
        {
          name: 'TRIGGER',
        },
      ],
    },
    postProcess: _.compact([...files, configFile]),
    dataTarget: [],
  }
}

export const getDownloadPayload = ({
  environment,
  fileName,
  bucket,
}: {
  environment: string
  fileName: string
  bucket: string
}): Payload => {
  return {
    dataSource: {
      source: 'inline',
      rows: [
        {
          name: 'TRIGGER',
        },
      ],
    },
    postProcess: [
      {
        module: 'awsS3',
        params: {
          operation: 'getObject',
          data: {
            inputTemplate: {
              Bucket: `${environment}-${bucket}`,
              Key: `output/excel_uploads/multi_day_optimization_result/${fileName}_optimization_result.xlsx`,
            },
          },
          inputConverter: ['$data.inputTemplate'],
          outputConverter:
            "{ 'base64Data': $bufferToBase64($s3Response.Body) }",
          onError: 'emit',
        },
      },
    ],
    dataTarget: [],
  }
}

export const getSheetTabData = (data: string, tab = 'status'): Payload[] => {
  if (!data) return []
  const resultExcel = XLSX.read(data, { type: 'base64' })
  return CSVArrayToObj(
    _.compact(XLSX.utils.sheet_to_json(resultExcel.Sheets[tab], { header: 1 }))
  )
}
