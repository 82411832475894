import { FormValidation } from '@rjsf/utils'
import moment from 'moment-timezone'

import {
  DATE_TIME_WIDGET_ERROR_DISPLAY_FORMATS,
  DATE_TIME_WIDGET_PARSE_FORMATS,
} from 'constants/datetime'
import { FORM_VALIDATION_ERRORS_MAP } from 'constants/formBuilder'

import { displayTime } from 'helpers/utils'

import type { DateTimeSchema, DateTimeUiSchema } from 'types/formBuilder'

const COMPARISON_GRANULARITY = 'minute'

const validateJsonFormDateTimeWidget = ({
  fieldId,
  schema,
  uiSchema,
  fieldValue,
  errors,
  timezone,
}: {
  fieldId: string
  schema: DateTimeSchema
  uiSchema: DateTimeUiSchema
  fieldValue?: string
  errors: FormValidation
  timezone: string
}): void => {
  const { start, end } = uiSchema['ui:options']

  // If there is no value or no start/end date – just do nothing
  if (!fieldValue || (!start && !end)) return

  const { format } = schema
  const parseFormat = DATE_TIME_WIDGET_PARSE_FORMATS[format]
  const startDateTime = moment.utc(start, parseFormat)
  const endDateTime = moment.utc(end, parseFormat)
  const currentValue = moment.utc(fieldValue, parseFormat)

  const commonParams = {
    timeFormat: DATE_TIME_WIDGET_ERROR_DISPLAY_FORMATS[format],
    addFromNow: false,
    displayLabel: false,
    timezone,
  }

  // Prepare dates to be displayed in the error message
  const startTime = displayTime({
    ...commonParams,
    datetime: startDateTime,
  })
  const endTime = displayTime({
    ...commonParams,
    datetime: endDateTime,
  })

  // Has 'end' but has no 'start'
  const isAfterEnd =
    !start &&
    end &&
    !currentValue.isSameOrBefore(endDateTime, COMPARISON_GRANULARITY)

  // Has 'start' but has no 'end'
  const isBeforeStart =
    start &&
    !end &&
    !currentValue.isSameOrAfter(startDateTime, COMPARISON_GRANULARITY)

  // Has both boundaries
  const isNotBetween =
    start &&
    end &&
    !currentValue.isBetween(
      startDateTime,
      endDateTime,
      COMPARISON_GRANULARITY,
      '[]'
    )

  if (isAfterEnd) {
    errors[fieldId]?.addError(
      FORM_VALIDATION_ERRORS_MAP.timeBefore({ params: { endTime } })
    )
  } else if (isBeforeStart) {
    errors[fieldId]?.addError(
      FORM_VALIDATION_ERRORS_MAP.timeAfter({ params: { startTime } })
    )
  } else if (isNotBetween) {
    errors[fieldId]?.addError(
      FORM_VALIDATION_ERRORS_MAP.timeBetween({
        params: { startTime, endTime },
      })
    )
  }
}

export default validateJsonFormDateTimeWidget
