// libraries
import React, { useCallback, useEffect } from 'react'
import _ from 'lodash'
import isEqual from 'fast-deep-equal'

// components
import { MapPopup } from 'components/map/controls'

// constants
import {
  INTERACTION_TYPES,
  PROFILE_POPUP_OFFSET_X,
  PROFILE_POPUP_OFFSET_Y,
  DEFAULT_PROFILE_PROPERTIES_SETTING,
  LAYER_PROFILE_TYPES,
} from 'constants/profile'
import { NAME_PROPERTY_KEY } from 'constants/unipipe'
import { AGGREGATION_TYPES } from 'constants/aggregation'
import { DEFAULT_IDENTITY_PROPERTY } from 'constants/common'

// utils
import { getFeatureLatestProperties, isAssetLayer } from 'helpers/map'
import {
  isEventTriggeredByHovering,
  isEventTriggeredByClicking,
} from 'helpers/layerProfile'
import { switchcase, displayValue } from 'helpers/utils'
import { useMapClickedFeatures } from 'components/map/hooks'
import { isNumericType } from 'helpers/filter'
import { useMapStateValue, useStateValue } from 'contexts'
import { useTimezone } from 'hooks'
import { reportException } from 'helpers/log'

export const getPosition = (x, y) => ({
  x: x + PROFILE_POPUP_OFFSET_X,
  y: y + PROFILE_POPUP_OFFSET_Y,
})

export const getPropertiesArray = properties => {
  return _.map(properties, (value, key) => {
    return {
      key,
      value,
      label: key,
      isVisible: true,
    }
  })
}

const getLayerId = layer => (layer?.id ? layer.id.split('/')[1] : '')

const getProfileTitle = (object, identityProperty) => {
  return displayValue(_.get(object, ['properties', identityProperty]))
}

const getAggregatedPointsProfileList = (object, style, type) => {
  const { aggregationProperty, aggregationType } = style
  const { value: propertyValue } = aggregationProperty || {}
  const { properties } = object
  const label =
    aggregationType && aggregationType !== AGGREGATION_TYPES.count
      ? `${aggregationType} ${propertyValue}`
      : aggregationProperty
      ? isNumericType(type)
        ? AGGREGATION_TYPES.count
        : propertyValue
      : ''
  return propertyValue
    ? [
        {
          label,
          value: displayValue(_.get(properties, [propertyValue])),
        },
      ]
    : [
        {
          label: 'Count',
          value: displayValue(_.get(properties, 'count')),
        },
      ]
}

const getAggregatedProfileList = (object, style) => {
  const { aggregationForColour, aggregationForHeight } = style
  const { colorValue } = object
  const elevation = _.isNil(object.elevation)
    ? object.elevationValue
    : object.elevation
  return [
    {
      label: aggregationForColour?.type
        ? `${aggregationForColour.type} ${
            aggregationForColour.key || ''
          }(Colour)`
        : 'Count (Colour)',
      value: displayValue(colorValue),
    },
    {
      label: aggregationForHeight?.type
        ? `${aggregationForHeight.type} ${
            aggregationForHeight.key || ''
          } (Height)`
        : 'Count (Height)',
      value: displayValue(elevation),
    },
  ]
}

const getProfileProperties = ({ object, style, type }) => {
  const profileList = style.arePointsAggregated
    ? getAggregatedPointsProfileList(object, style, type)
    : getAggregatedProfileList(object, style)

  return profileList.map(({ label, value }) => ({
    label,
    value,
    ...DEFAULT_PROFILE_PROPERTIES_SETTING,
  }))
}

const shouldUpdateClickedPopup = (oldPopup, newPopup) => {
  const hasClickedBefore = isEventTriggeredByClicking(oldPopup?.pickedType)

  return !(
    hasClickedBefore &&
    (_.isEmpty(newPopup) || isEqual(oldPopup.name, newPopup.name))
  )
}

/**
 * Responsible for converting the hovered/clicked map feature to the popup.
 * Current handlers fail into two types: (1) non-aggregation layer(e.g., point,
 * icon, and geojson) profile handler and (2) aggregation layer(e.g., hexagon
 * and heatmap) profile handler
 */
const useMapProfileHandler = ({ mapRef }) => {
  const {
    getPopup,
    setPopup,
    getLayer,
    setMapClickedFeatures,
    map: { layers },
  } = useMapStateValue()

  const {
    selectors: {
      unipipeSelectors: { pickedDatasetsMetadata },
    },
  } = useStateValue()

  const { getFeatureWithProfileSetting, getClusteringPointsDisplayedValues } =
    useMapClickedFeatures()
  const { timezone } = useTimezone()

  const getMapLayer = useCallback(
    deckglLayer => {
      const layerId = getLayerId(deckglLayer)
      return getLayer(layerId)
    },
    [getLayer]
  )

  const aggregationProfileHandler = useCallback(
    props => {
      const {
        info: { object, layer, x, y, index },
        pickedType,
      } = props
      let popupProps = {}
      if (object) {
        const mapLayer = getMapLayer(layer)
        if (!mapLayer) return

        const { id: layerId, style, type, dataset } = mapLayer
        const { identityProperty = DEFAULT_IDENTITY_PROPERTY } =
          pickedDatasetsMetadata[dataset] || {}

        const properties = getProfileProperties({
          object,
          type,
          style: style[type],
        })

        popupProps = {
          id: `${layerId}-${index}`,
          title: getProfileTitle(object, identityProperty),
          properties,
          position: getPosition(x, y),
          object,
          layer,
          layerId,
          pickedType,
        }
      }
      if (isEventTriggeredByHovering(pickedType)) {
        setPopup(popupProps)
      }
    },
    [pickedDatasetsMetadata, getMapLayer, setPopup]
  )

  const getPopupProps = useCallback(
    ({
      object,
      layer,
      x,
      y,
      fetchLatestProperties = true,
      expand = true,
      cluster,
      clusterId,
      clusterPointCount,
      ...rest
    }) => {
      const popup = getPopup()
      const { layerId } = layer.props

      const commonProps = { object, layerId, expand, cluster, ...rest }

      if (
        !fetchLatestProperties ||
        (layerId === popup?.layerId && isEqual(object, popup?.object))
      )
        return { ...popup, ...commonProps }

      if (cluster) {
        const { properties, layerName } = getClusteringPointsDisplayedValues({
          layerId,
          object,
        })

        return {
          ...commonProps,
          position: getPosition(x, y),
          properties,
          name: clusterPointCount,
          title: `${clusterPointCount} ${layerName}`,
          id: `${layerId}-${clusterId}`,
        }
      }

      const feature = getFeatureWithProfileSetting({ layerId, object })
      const oldFeature = popup
      const latestFeatureProperties = getFeatureLatestProperties(
        feature,
        oldFeature
      )
      return {
        ...latestFeatureProperties,
        position: getPosition(x, y),
        title: latestFeatureProperties.title,
        ...commonProps,
        id: `${layerId}-${latestFeatureProperties?.name}-${object.properties?.time}`,
      }
    },
    [getPopup, getFeatureWithProfileSetting, getClusteringPointsDisplayedValues]
  )

  /**
   * Generate multiple map profiles and display them on the MapOverlay
   */
  const getClickedFeatures = useCallback(
    ({ x, y, info }) => {
      const { cluster } = info
      if (cluster) {
        setMapClickedFeatures(oldMapClickedFeatures => {
          const clustering = _.omit(
            getPopupProps({
              ...info,
              pickedType: INTERACTION_TYPES.click,
              expand: false,
            }),
            ['position']
          )

          const mapClickedFeatures = oldMapClickedFeatures || []

          return _.uniqBy([clustering, ...mapClickedFeatures], 'id')
        })
        return undefined
      }
      if (!mapRef) return undefined

      // https://deck.gl/docs/api-reference/core/deck#pickmultipleobjects
      const multipleObjects = mapRef.current.pickMultipleObjects({
        x,
        y,
        radius: 2,
      })

      const multiplePopups = multipleObjects.map(item => {
        return getPopupProps({
          ...item,
          layer: item.layer,
          pickedType: INTERACTION_TYPES.click,
          expand: false,
        })
      })

      setMapClickedFeatures(oldMapClickedFeatures => {
        const mapClickedFeatures = oldMapClickedFeatures || []

        return _.uniqBy([...multiplePopups, ...mapClickedFeatures], 'id')
      })

      return undefined
    },
    [getPopupProps, mapRef, setMapClickedFeatures]
  )

  const getNonAggregationProfile = useCallback(
    props => {
      const { info, pickedType, fetchLatestProperties } = props
      const isHoverAction = isEventTriggeredByHovering(pickedType)

      let popupProps

      const { object, x, y, cluster } = info
      if (object) {
        if (cluster ? isEventTriggeredByClicking(pickedType) : isHoverAction) {
          popupProps = getPopupProps({
            ...info,
            pickedType: INTERACTION_TYPES.hover,
            fetchLatestProperties,
          })
        } else if (!cluster) {
          getClickedFeatures({ x, y, info })
        }
      }
      return popupProps
    },
    [getClickedFeatures, getPopupProps]
  )

  const nonAggregationProfileHandler = useCallback(
    props => {
      const popupProps = getNonAggregationProfile(props)
      setPopup(oldPopup => {
        const { cluster } = oldPopup || {}
        if (!popupProps && cluster) return oldPopup

        return isEqual(popupProps, oldPopup) ? oldPopup : popupProps
      })
    },
    [getNonAggregationProfile, setPopup]
  )

  const issueProfileHandler = useCallback(
    props => {
      const { info } = props
      const { object } = info
      const { properties } = object || {}
      nonAggregationProfileHandler({
        ...props,
        pickedType: INTERACTION_TYPES.hover,
        info: {
          ...info,
          title: properties?.title,
          properties: getPropertiesArray(properties),
        },
      })
    },
    [nonAggregationProfileHandler]
  )

  const getAssetProfileTitle = useCallback(
    ({ title, properties, displayName, identityPropertyValue }) => {
      const newTitle = title
        ? _.get(properties, title)
        : displayName || identityPropertyValue || properties?.title
      return displayValue(newTitle, timezone, false)
    },
    [timezone]
  )

  const assetProfileHandler = useCallback(
    props => {
      const { info, pickedType } = props
      const { object, layer } = info

      if (isEventTriggeredByHovering(pickedType)) {
        return
      }

      let popupProps = {}
      if (object) {
        const mapLayer = getMapLayer(layer)
        if (!mapLayer) return

        const {
          dataset,
          profile: { title },
        } = mapLayer
        const { identityProperty = DEFAULT_IDENTITY_PROPERTY, assetProfile } =
          pickedDatasetsMetadata[dataset] || {}
        if (!isAssetLayer(mapLayer) && !assetProfile) {
          reportException(
            `[Layer:${mapLayer.name}] Asset profile is not valid`,
            {
              mapLayer,
              datasetMetadata: pickedDatasetsMetadata[dataset],
            }
          )
          return
        }

        const { properties, displayName } = object
        const identityPropertyValue = _.get(properties, identityProperty)
        popupProps = {
          ...getNonAggregationProfile({
            ...props,
            info: {
              ...info,
              ...(!isAssetLayer(mapLayer) && {
                object: {
                  ...info.object,
                  id: identityPropertyValue,
                  profile: assetProfile,
                },
              }),
              title: getAssetProfileTitle({
                title,
                displayName,
                properties,
                identityPropertyValue,
              }),
              properties: getPropertiesArray(properties),
            },
            pickedType: INTERACTION_TYPES.hover,
            fetchLatestProperties: false,
          }),
          pickedType,
          profileType: LAYER_PROFILE_TYPES.asset,
          name: identityPropertyValue,
        }
      }

      setPopup(oldPopup =>
        shouldUpdateClickedPopup(oldPopup, popupProps) ? popupProps : oldPopup
      )
    },
    [
      setPopup,
      getMapLayer,
      pickedDatasetsMetadata,
      getNonAggregationProfile,
      getAssetProfileTitle,
    ]
  )

  const journeyProfileHandler = useCallback(
    props => {
      const { info, pickedType } = props
      const journeyProfileType = LAYER_PROFILE_TYPES.journey

      const oldPopup = getPopup()
      const shouldDisplayHoverProfile =
        !isEventTriggeredByClicking(pickedType) &&
        oldPopup?.profileType !== journeyProfileType

      if (shouldDisplayHoverProfile) {
        nonAggregationProfileHandler(props)
        return
      }

      const { object, layer } = info
      let popupProps = {}
      if (object) {
        popupProps = getPopupProps({
          ...info,
          layer,
          pickedType,
          profileType: journeyProfileType,
        })
      }

      setPopup(oldPopupProps =>
        shouldUpdateClickedPopup(oldPopupProps, popupProps)
          ? popupProps
          : oldPopupProps
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getPopup(), nonAggregationProfileHandler, getPopupProps, setPopup]
  )

  const closePopup = useCallback(() => {
    setPopup(null)
  }, [setPopup])

  const updatePopup = useCallback(() => {
    setPopup(oldPopup => {
      const layer = getLayer(oldPopup?.layerId)
      if (!layer || !layer.isVisible) return null

      const { profile } = oldPopup.object
      if (profile && layer.profile?.assetProfileId !== profile) return null

      return oldPopup
    })
  }, [getLayer, setPopup])

  useEffect(() => {
    updatePopup(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers])

  const updatePopupProperties = useCallback(() => {
    setPopup(oldPopup => {
      if (_.isEmpty(oldPopup) || oldPopup?.cluster) return oldPopup

      const feature = getFeatureWithProfileSetting(oldPopup)
      if (!feature) return oldPopup

      const oldFeature = _.find(oldPopup, [NAME_PROPERTY_KEY, feature.name])
      const latestPopupProperties = getFeatureLatestProperties(
        feature,
        oldFeature
      )

      if (!latestPopupProperties) return oldPopup

      return {
        ...oldPopup,
        ...latestPopupProperties,
      }
    })
  }, [getFeatureWithProfileSetting, setPopup])

  const profileHandler = useCallback(
    (profileType, pickedType) => info => {
      const { cluster } = info
      const isAssetProfileWithClustering =
        !!cluster && profileType === LAYER_PROFILE_TYPES.asset

      const handlerFn = switchcase({
        [LAYER_PROFILE_TYPES.aggregated]: aggregationProfileHandler,
        [LAYER_PROFILE_TYPES.issue]: issueProfileHandler,
        [LAYER_PROFILE_TYPES.journey]: journeyProfileHandler,
        [LAYER_PROFILE_TYPES.asset]: isAssetProfileWithClustering
          ? nonAggregationProfileHandler
          : assetProfileHandler,
      })(nonAggregationProfileHandler)(profileType)
      handlerFn({ info, pickedType })
    },
    [
      aggregationProfileHandler,
      issueProfileHandler,
      assetProfileHandler,
      journeyProfileHandler,
      nonAggregationProfileHandler,
    ]
  )

  const renderMapProfiles = useCallback(() => {
    const popup = getPopup()
    const { pickedType, properties, cluster } = popup || {}
    const isClusteringHover =
      isEventTriggeredByHovering(pickedType) && !!cluster

    return (
      properties && (
        <MapPopup
          {...((isEventTriggeredByClicking(pickedType) ||
            isClusteringHover) && {
            onClose: closePopup,
          })}
          key={popup.id}
          popup={popup}
          withTimeFilter={false}
        />
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closePopup, getPopup()])

  return {
    popup: getPopup(),
    setPopup,
    profileHandler,
    updatePopupProperties,
    renderMapProfiles,
  }
}

export default useMapProfileHandler
