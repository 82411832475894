// libraries
import { ReactElement } from 'react'
import _ from 'lodash'
import { ColumnBodyOptions } from 'primereact/column'

// constants
import { AVATAR_SIZES, DEFAULT_USER, UNASSIGNED_USERNAME } from 'constants/user'

// components
import { Avatar, IssueAssigneesPicker } from 'components/common'

import type { IssueGeojson } from 'types/issue'

const Template = ({
  rowData,
  options: { field },
}: {
  rowData: IssueGeojson
  options: ColumnBodyOptions
}): ReactElement => {
  const readOnly = true
  const assignee = _.get(rowData, field)
  return (
    <IssueAssigneesPicker
      bgColour='transparent'
      value={assignee}
      readOnly={readOnly}
      placeholder={
        assignee && !readOnly ? undefined : (
          <div className='d-flex align-items-center'>
            <Avatar
              user={DEFAULT_USER}
              size={AVATAR_SIZES.small}
              useDefaultAvatar
            />
            <span className='ms-2'>{UNASSIGNED_USERNAME}</span>
          </div>
        )
      }
    />
  )
}

const AssigneeTemplate = (
  rowData: unknown,
  options: ColumnBodyOptions
): ReactElement => {
  return <Template rowData={rowData} options={options} />
}

export default AssigneeTemplate
