// libraries
import { useMemo, useState } from 'react'
import _ from 'lodash'
import { useUpdateEffect } from 'react-use'
import { ColumnProps } from 'primereact/column'
import {
  TABLE_COLUMN_GROUP_BY_FIELD_KEY,
  TABLE_COLUMN_SUPPORT_GROUP_BY_KEY,
} from 'constants/common'

const DEFAULT_COLUMNS_OPTIONS = {
  headerStyle: undefined,
  bodyStyle: undefined,
  sortable: true,
  resizeable: true,
}

// Making a copy of the default type so if we need to add something later,
// we can do it just here
export type ColumnOptions = ColumnProps & {
  key?: string
}

export type Column = Pick<
  ColumnOptions,
  | 'header'
  | 'body'
  | 'field'
  | 'hidden'
  | 'style'
  | 'sortable'
  | 'key'
  | 'bodyStyle'
>

export type Columns = Column[]

export type ColumnWithGroupable = Column & {
  [TABLE_COLUMN_SUPPORT_GROUP_BY_KEY]?: boolean
  [TABLE_COLUMN_GROUP_BY_FIELD_KEY]?: string
}

export type SetVisibleColumns = (columnsData: ColumnOptions[]) => void

export type ColumnsWithGroupable = ColumnWithGroupable[]

export const getTableGroupableColumns = (
  columns: ColumnsWithGroupable
): ColumnsWithGroupable => _.filter(columns, TABLE_COLUMN_SUPPORT_GROUP_BY_KEY)

const calcVisibleColumns = ({
  initialVisibleColumns,
  columnsData,
}: {
  initialVisibleColumns?: string[]
  columnsData: Columns
}) =>
  initialVisibleColumns?.length
    ? _.filter<ColumnOptions>(columnsData, ({ field }) =>
        _.includes(initialVisibleColumns, field)
      )
    : columnsData

export const useDataTableColumns = ({
  columns = [],
  options,
  initialVisibleColumns,
}: {
  columns?: Columns
  options?: ColumnOptions
  initialVisibleColumns?: string[]
}): {
  columns: ColumnOptions[]
  visibleColumns: ColumnOptions[]
  setVisibleColumns: SetVisibleColumns
} => {
  const columnsData = useMemo(
    () =>
      columns.map(col =>
        _.omit(
          {
            ..._.defaults({}, options, DEFAULT_COLUMNS_OPTIONS),
            ...col,
          },
          TABLE_COLUMN_SUPPORT_GROUP_BY_KEY
        )
      ),
    [columns, options]
  )

  // * For now, the 'columns' prop is static (constant) so we don't need to worry about 'refreshing' the state data.
  const [visibleColumns, setVisibleColumns] = useState<ColumnOptions[]>(() =>
    // Apply the preserved state if we have it
    calcVisibleColumns({ initialVisibleColumns, columnsData })
  )

  useUpdateEffect(() => {
    setVisibleColumns(columnsData)
  }, [columnsData])

  return {
    columns: columnsData,
    visibleColumns,
    setVisibleColumns,
  }
}
