// libraries
import styled from '@emotion/styled'
// constants
import { TABLE_HEADER_HEIGHT } from './constants'

export const SelectRowCheckBox = styled.div`
  button {
    color: ${props => props.theme.primary};
  }
`

type StyledDataTableProp = { isLightTheme: boolean; headerHeight: number }

export const StyledDataTable = styled.div<StyledDataTableProp>`
  .p-component {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: normal;
  }

  tbody {
    max-width: 100%; //fix mis-alignment
    // Subtract a table header height from the block height to fix the scrollbar
    min-height: calc(
      100% - ${props => props.headerHeight ?? TABLE_HEADER_HEIGHT}px
    );
  }

  .p-datatable .p-datatable-tbody > tr {
    background: ${props => (props.isLightTheme ? '#fff' : props.theme.dark)};
    color: ${props =>
      props.isLightTheme
        ? props.theme['secondary-700']
        : props.theme['secondary-light-600']};
    transition: box-shadow 0.2s;
    border-left: 1px solid
      ${props =>
        props.isLightTheme
          ? `${props.theme['secondary-100']}`
          : `${props.theme['primary-800']}`};
    border-right: 1px solid
      ${props =>
        props.isLightTheme
          ? `${props.theme['secondary-100']}`
          : `${props.theme['primary-800']}`};
    border-bottom: 1px solid
      ${props =>
        props.isLightTheme
          ? `${props.theme['secondary-100']}`
          : `${props.theme['primary-800']}`};
  }
  .p-datatable .p-datatable-tbody > tr:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .p-datatable-tbody .p-virtualscroller-content {
    max-width: 100%;
  }

  .p-datatable .p-datatable-thead > tr > th {
    background: transparent !important;
    border: none;
    color: ${props =>
      props.isLightTheme
        ? props.theme['secondary-700']
        : props.theme['secondary-light-600']};
  }

  .p-datatable .p-datatable-thead > tr > th:not(:last-child):before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 16px;
    width: 1px;
    z-index: -1;
    background: ${props =>
      props.isLightTheme
        ? `${props.theme['secondary-100']}`
        : `${props.theme['primary-800']}`};
  }

  .p-virtualscroller {
    border-radius: 4px;
  }

  .p-datatable .p-datatable-tbody > tr .userPicker {
    color: ${props =>
      props.isLightTheme
        ? props.theme['secondary-700']
        : props.theme['secondary-light-600']};
  }

  .p-datatable .p-datatable-tbody > tr > td {
    border: none;
  }

  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: ${props =>
      props.isLightTheme
        ? props.theme['secondary-light-500']
        : props.theme['primary-900']};
    color: ${props => props.theme.primary};
  }

  .p-datatable.p-datatable-selectable
    .p-datatable-tbody
    > tr.p-selectable-row:focus {
    outline: 0 none;
  }

  .p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.15rem ${props => props.theme.primary};
    outline: 0 none;
  }

  .actionMenu,
  .actionMenuFlex {
    display: none;
  }
  .p-datatable .danger {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: calc(100% - 1px);
      width: 3px;
      background-color: ${props => props.theme['red-500']};
    }
  }

  .p-datatable.p-datatable-selectable
    .p-datatable-tbody
    > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    background: ${props =>
      props.isLightTheme
        ? props.theme['secondary-light-500']
        : props.theme['primary-900']};
    color: ${props => props.theme.primary};

    .actionMenu {
      display: block;
    }
    .actionMenuFlex {
      display: flex;
    }
  }

  .p-datatable.p-datatable-selectable
    .p-datatable-tbody
    > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover
    .userPicker {
    color: ${props => props.theme.primary};
  }

  .p-datatable
    .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled)
    .p-sortable-column-icon {
    opacity: 0;
  }

  .p-datatable
    .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover
    .p-sortable-column-icon {
    opacity: 1;
    color: ${props => props.theme.primary};
  }

  .p-datatable
    .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: ${props => props.theme['primary-100']};
    color: ${props => props.theme.primary};
  }

  .p-datatable .p-sortable-column.p-highlight {
    background: ${props => props.theme['primary-100']};
    color: ${props => props.theme.primary};
  }

  .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: ${props => props.theme['primary-100']};
    color: ${props => props.theme.primary};
  }

  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: ${props => props.theme.primary};
  }

  .p-datatable
    .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover
    .p-sortable-column-icon {
    color: ${props => props.theme.primary};
  }

  .p-datatable .p-datatable-loading-overlay {
    /* Just preventing interactions with the table while it's loading */
    background-color: transparent;
    border-radius: 4px;
  }

  .p-datatable .p-datatable-loading-icon {
    display: none;
  }

  .p-datatable-footer {
    padding: 8px;
  }
  .p-datatable-thead {
    background: ${props =>
      props.isLightTheme
        ? `${props.theme['secondary-50']}`
        : `${props.theme['primary-900']}`} !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid
      ${props =>
        props.isLightTheme
          ? `${props.theme['secondary-100']}`
          : `${props.theme['primary-800']}`};
  }

  .p-datatable-resizable .p-datatable-thead > tr > th {
    white-space: normal;
  }

  /** Styles from v8 START */
  .p-datatable-scrollable .p-datatable-tbody > tr,
  .p-datatable-scrollable .p-datatable-tfoot > tr,
  .p-datatable-scrollable .p-datatable-thead > tr {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  .p-datatable-scrollable .p-datatable-tbody > tr > td,
  .p-datatable-scrollable .p-datatable-tfoot > tr > td,
  .p-datatable-scrollable .p-datatable-thead > tr > th {
    display: flex;
    flex: 1 1;
    align-items: center;
  }

  .p-datatable-scrollable .p-datatable-tbody,
  .p-datatable-scrollable .p-datatable-tfoot,
  .p-datatable-scrollable .p-datatable-thead {
    display: block;
  }

  .p-datatable-scrollable .p-virtualscroller > .p-datatable-table {
    display: inline-block;
  }
  /** Styles from v8 END */
`

export const DropdownItem = styled.div`
  :hover {
    background: ${props => props.theme['primary-100']};
  }
`
