import { ReactElement } from 'react'

// utils
import { displayTime } from 'helpers/utils'
import { useTimezone } from 'hooks'

// types
import type { UtcISOString } from 'types/datetime'

import scss from './index.module.scss'

const Signature = ({
  imageUrl,
  timestamp,
}: {
  imageUrl: string
  timestamp: UtcISOString
}): ReactElement => {
  const { timezone } = useTimezone()

  return (
    <>
      <div className={scss.imageContainer}>
        <img className={scss.image} src={imageUrl} alt='signature' />
      </div>
      <div className='text-secondary mt-1'>
        {displayTime({
          datetime: timestamp,
          timezone,
          addFromNow: false,
          displayLabel: true,
        })}
      </div>
    </>
  )
}

export default Signature
