import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  ReactElement,
  ComponentProps,
  forwardRef,
} from 'react'
import CreatableSelect from 'react-select/creatable'
import AsyncSelect from 'react-select/async'
import _ from 'lodash'
import Select, {
  createFilter,
  ActionMeta,
  StylesConfig,
  SelectComponentsConfig,
  components as ReactSelectComponents,
  Props as ReactSelectProps,
} from 'react-select'

// utils
import {
  findOptionsByArrays,
  findOptionByValue,
  findOptionFromGroupedOptionsByValue,
} from 'helpers/filter'
import { getConvertedStringValue } from 'helpers/utils'
import useSelectStyles from 'components/common/MultiSelect/useSelectStyles'
import type { ColourArray, Value, Options, Payload } from 'types/common'

// constants
import { NO_DATA_PLACEHOLDER } from 'constants/common'

// components
import { MdOutlineArrowDropDown } from 'components/icons'
import { OptionLabel } from './components'

export type SelectOption = {
  value: Value
  label: string
  variables?: Value | Value[]
}

export type SelectOptions = SelectOption[] | SelectOption | undefined

const getSelectComponent = ({
  creatable,
  isAsync,
}: {
  creatable?: boolean
  isAsync?: boolean
}) => {
  if (creatable) return CreatableSelect
  if (isAsync) return AsyncSelect
  return Select
}

const getNoOptionsMessage = ({
  value,
  maxOptionsLength = Infinity,
  noDataMessage = NO_DATA_PLACEHOLDER,
}: {
  value: Options | null | undefined
  maxOptionsLength?: number
  noDataMessage?: string
}) => {
  if (_.isNil(value)) return false
  return value.length === maxOptionsLength
    ? "You've reached the max number of options."
    : noDataMessage
}

const customFilterOption = createFilter({
  stringify: option => `${option.label}`, // Default is `${option.label} ${option.value}`
})

const DropdownIndicator = (
  props: ComponentProps<typeof ReactSelectComponents.DropdownIndicator>
) => {
  return (
    <ReactSelectComponents.DropdownIndicator {...props}>
      <MdOutlineArrowDropDown size={24} />
    </ReactSelectComponents.DropdownIndicator>
  )
}

const optionKey = 'value'
const optionLabel = 'label'

export type MultiSelectProp = Partial<ReactSelectProps> & {
  name?: string
  value?: Value | Value[] | null
  useOptionValueOnly?: boolean
  options?: SelectOptions
  className?: string
  placeholder?: string
  isMulti?: boolean
  isSearchable?: boolean | string
  isAsync?: boolean
  isDisabled?: boolean | string
  isLarge?: boolean
  noDataMessage?: string
  isClearable?: boolean
  preSelect?: boolean
  hideSelectedOptions?: boolean
  creatable?: boolean
  convertStringType?: string
  withBorder?: boolean
  maxOptionsLength?: number
  group?: string
  bgColour?: ColourArray | string
  removeControl?: boolean
  dropdownOnly?: boolean
  hasError?: boolean
  zIndex?: number | string
  onChange: (option?: SelectOption | SelectOptions | Value) => void
  onCreatableOptionsChange?: (option?: SelectOption | Value) => void
  isLoading?: boolean
  removeInvalidValues?: boolean // !Be cautious to use this prop because this would remove the saved value
  readOnly?: boolean
}

export const CLASS_NAME_PREFIX = 'select'

export const getMultiSelectCommonProps = ({
  isMulti = true,
  className,
  value,
  maxOptionsLength = Infinity,
  noDataMessage = NO_DATA_PLACEHOLDER,
  customStyles,
  components,
  formatOptionLabel,
}: {
  isMulti: boolean
  className?: string
  value: Options | null | undefined
  maxOptionsLength?: number
  noDataMessage?: string
  customStyles: StylesConfig
  components?: SelectComponentsConfig
  formatOptionLabel: MultiSelectProp['formatOptionLabel']
}): Payload => ({
  styles: customStyles,
  closeMenuOnSelect: !isMulti,
  className: `customSelect ${className}`,
  classNamePrefix: CLASS_NAME_PREFIX,
  isMulti,
  noOptionsMessage: () =>
    getNoOptionsMessage({
      value,
      maxOptionsLength,
      noDataMessage,
    }),
  formatCreateLabel: (v: string) => `+ Add "${v}"`,
  filterOption: customFilterOption,
  formatOptionLabel: formatOptionLabel || OptionLabel,
  components: { ...components, DropdownIndicator },
})

const MultiSelect = forwardRef(
  (
    {
      value,
      options = [],
      onChange,
      isMulti = true,
      group,
      className,
      noDataMessage,
      components,
      preSelect,
      bgColour,
      useOptionValueOnly,
      creatable,
      convertStringType,
      onCreatableOptionsChange = _.noop,
      formatOptionLabel,
      withBorder,
      maxOptionsLength,
      removeControl,
      name,
      removeInvalidValues = false,
      isLoading,
      dropdownOnly = false,
      hasError = false,
      zIndex,
      isDisabled,
      isLarge,
      isAsync,
      readOnly,
      ...rest
    }: MultiSelectProp,
    ref
  ): ReactElement => {
    const [selectedOptions, setSelectedOptions] =
      // For an async select, make sure that the selected value is preselected on mount
      useState<SelectOptions | null>(isAsync ? value : null)

    const { customStyles } = useSelectStyles({
      bgColour,
      withBorder,
      removeControl,
      dropdownOnly,
      hasError,
      isLarge,
      zIndex,
      readOnly,
    })

    const onChangeHandler = useCallback(
      (option: SelectOption, actionMeta: ActionMeta<SelectOption>) => {
        const { value: newSelectedValue } = option || {}
        const newOption = convertStringType
          ? {
              ...option,
              value: getConvertedStringValue(
                newSelectedValue as string,
                convertStringType
              ),
            }
          : option

        setSelectedOptions(newOption)
        if (_.isFunction(onChange)) {
          const getOptionsValue = () => {
            return isMulti
              ? _.map(newOption, optionKey)
              : newOption?.[optionKey]
          }

          const newValue = useOptionValueOnly ? getOptionsValue() : newOption

          onChange(newValue)
          const { action } = actionMeta
          if (action === 'create-option') {
            onCreatableOptionsChange(newValue)
          }
        }
      },
      [
        convertStringType,
        isMulti,
        onChange,
        onCreatableOptionsChange,
        useOptionValueOnly,
      ]
    )

    // using undefined to clear the value is in general bad practice. Use null is recommend when we want to clear the value of any Select component provided by this library.
    // https://github.com/JedWatson/react-select/issues/3066
    useEffect(
      () => {
        // If that's the AsyncSelect, it will handle the value by itself
        if (isLoading || isAsync) return

        const findSingleOptionValue = (): SelectOption =>
          _.isEmpty(group)
            ? findOptionByValue(options, value, optionKey, preSelect)
            : findOptionFromGroupedOptionsByValue({
                options,
                value,
                group,
                optionKey,
                optionLabel,
              })

        const getOption = () =>
          isMulti
            ? (findOptionsByArrays(options, optionKey, value) as SelectOptions)
            : findSingleOptionValue()

        const isInvalidValue = _.isNil(value) || value === ''

        const option =
          !preSelect && (isInvalidValue || _.isEmpty(options))
            ? null
            : getOption()

        if (preSelect && option && isInvalidValue) {
          onChange(
            useOptionValueOnly && !isMulti
              ? (option as SelectOption).value
              : option
          )
        }

        if (removeInvalidValues) {
          // !Be cautious to use this prop because this would remove the saved value
          if (isMulti) {
            const newOptionValues = _.map(option, 'value')

            if (!_.isEqual(newOptionValues, value)) {
              onChange(useOptionValueOnly ? newOptionValues : option)
            }
          } else if (!_.isNil(value) && option?.value !== value) {
            onChange(useOptionValueOnly ? option?.value : option)
          }
        }
        setSelectedOptions(option)
      }, // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        value,
        options,
        group,
        isMulti,
        optionLabel,
        optionKey,
        preSelect,
        isAsync,
      ]
    )

    const Component = useMemo(
      () => getSelectComponent({ creatable, isAsync }),
      [creatable, isAsync]
    )

    const validOptions = useMemo(() => {
      return selectedOptions?.length === maxOptionsLength ? [] : options
    }, [maxOptionsLength, options, selectedOptions])

    const isDisabledOrReadOnly = isDisabled || readOnly

    const shouldDisableWhenOnlyOneOption = useMemo(() => {
      if (isMulti) return isDisabledOrReadOnly

      return (
        isDisabledOrReadOnly ||
        (validOptions.length === 1 && selectedOptions === validOptions)
      )
    }, [isMulti, selectedOptions, isDisabledOrReadOnly, validOptions])

    return (
      <Component
        name={name}
        {...rest}
        ref={ref}
        isDisabled={shouldDisableWhenOnlyOneOption}
        isLoading={isLoading}
        options={validOptions}
        value={selectedOptions ?? null}
        {...(!isDisabledOrReadOnly && { onChange: onChangeHandler })}
        {...getMultiSelectCommonProps({
          isMulti,
          className,
          value,
          maxOptionsLength,
          noDataMessage,
          customStyles,
          components,
          formatOptionLabel,
          readOnly,
        })}
      />
    )
  }
)

MultiSelect.defaultProps = {
  placeholder: 'Select',
  isMulti: true,
  isDisabled: false,
  readOnly: false,
  isClearable: true,
  isSearchable: true,
  noDataMessage: 'No data',
  preSelect: false,
  hideSelectedOptions: true,
  useOptionValueOnly: false,
  creatable: false,
  onCreatableOptionsChange: _.noop,
  withBorder: false,
  maxOptionsLength: Infinity,
  removeControl: false,
  isLoading: false,
  removeInvalidValues: false,
}

export default MultiSelect
