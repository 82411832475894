/* eslint-disable no-shadow */
// libraries
import React, { useState, useCallback, useMemo } from 'react'
import _ from 'lodash'
import Autosuggest from 'react-autosuggest'
import { MdSearch, MdClose } from 'react-icons/md'
import autosuggestHighlightParse from 'autosuggest-highlight/parse'
import { useToggle } from 'react-use'
import PropTypes from 'prop-types'

// contexts
import { useMapStateValue } from 'contexts'

// utils
import { useTimezone } from 'hooks'
import {
  isStringContainsCharacters,
  displayTime,
  sanitizeString,
} from 'helpers/utils'

import autosuggestHighlightMatch from './utils/autosuggestHighlightMatch'

import scss from './index.module.scss'

// Calculate suggestions for given input value.
const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

// Calculate the input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name

const renderSuggestion =
  timezone =>
  (suggestion, { query }) => {
    const { name, time } = suggestion

    const matches = autosuggestHighlightMatch(name, query, true)
    const parts = autosuggestHighlightParse(name, matches)

    return (
      <div>
        <span>
          {parts.map(part => {
            const className = part.highlight ? 'text-primary' : null

            return (
              <span className={className} key={`${name}-${part.text}`}>
                {part.text}
              </span>
            )
          })}
        </span>
        {time && (
          <span>
            {' '}
            --{' '}
            {displayTime({
              datetime: time,
              timezone,
              addFromNow: false,
              displayLabel: false,
            })}
            )
          </span>
        )}
      </div>
    )
  }

const renderSectionTitle = section => <strong>{section.name}</strong>

const getSectionSuggestions = section => section.features

const MapSearchBar = ({ buttonClassName, iconClassName }) => {
  const { getMapSuggestions, setSearchFeatureSelected } = useMapStateValue()
  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [showSearchBar, toggleSearchBar] = useToggle(false)
  const { timezone } = useTimezone()

  const getSuggestions = useCallback(
    val => {
      const escapedValue = escapeRegexCharacters(val.trim())

      if (escapedValue === '') {
        return []
      }
      return getMapSuggestions()
        .map(({ name, features }) => ({
          name,
          features: features.filter(feature =>
            isStringContainsCharacters(feature.name, escapedValue)
          ),
        }))
        .filter(({ features }) => !_.isEmpty(features))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getMapSuggestions()]
  )

  const onChange = useCallback(
    (event, { newValue }) => {
      setValue(newValue)
      if (!newValue) {
        setSearchFeatureSelected({})
      }
    },
    [setSearchFeatureSelected]
  )

  const onSuggestionsFetchRequested = useCallback(
    ({ value: v }) => {
      const newSuggestions = getSuggestions(v)
      setSuggestions(newSuggestions)
    },
    [getSuggestions]
  )

  const onSuggestionsClearRequested = useCallback(() => setSuggestions([]), [])

  const onSuggestionSelected = useCallback(
    (event, { suggestion }) => {
      const newSuggestion = { ...suggestion, flyTo: true }
      setSearchFeatureSelected(newSuggestion)
    },
    [setSearchFeatureSelected]
  )

  const searchIcon = useMemo(
    () => (
      <button
        type='button'
        disabled={_.isEmpty(getMapSuggestions())}
        onClick={() => toggleSearchBar(!showSearchBar)}
        className={`${scss.searchButton} ${buttonClassName}`}
      >
        <MdSearch className={`${scss.searchIcon} ${iconClassName}`} />
      </button>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getMapSuggestions(), showSearchBar, toggleSearchBar]
  )

  const clearButton = useMemo(
    () =>
      value.length >= 1 &&
      showSearchBar && (
        <button
          className={scss.clearButton}
          type='button'
          onClick={() => setValue('')}
        >
          <MdClose className={scss.clearIcon} />
        </button>
      ),
    [showSearchBar, value]
  )

  const inputProps = {
    placeholder: 'Search name ...',
    value,
    onChange,
  }

  const renderInputComponent = ({ onChange, ...rest }) => {
    const handleChange = v => {
      const cleanText = sanitizeString(v)
      onChange(cleanText)
    }

    return (
      <div>
        <input {...rest} onChange={handleChange} />
        {clearButton}
      </div>
    )
  }

  return _.isEmpty(getMapSuggestions()) ? (
    <></>
  ) : (
    <>
      {searchIcon}
      {showSearchBar && (
        <Autosuggest
          multiSection
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion(timezone)}
          renderSectionTitle={renderSectionTitle}
          getSectionSuggestions={getSectionSuggestions}
          onSuggestionSelected={onSuggestionSelected}
          inputProps={inputProps}
          renderInputComponent={renderInputComponent}
          theme={scss}
        />
      )}
    </>
  )
}

MapSearchBar.propTypes = {
  buttonClassName: PropTypes.string,
  iconClassName: PropTypes.string,
}

MapSearchBar.defaultProps = {
  buttonClassName: '',
  iconClassName: '',
}

export default MapSearchBar
