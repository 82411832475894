import keymirror from 'keymirror'
import { makeMethaneAppEntityName } from 'app/MissionControlMethaneSolution/helpers/common'

const TAB_DETECTION_SUFFIX = ':tab/:id?'

export const INBOX_URL = `/inbox`
export const EMISSIONS_URL = '/emissions'
export const WIP_URL = `/wip`
export const DETECTIONS_URL = `/detections`
export const SITES_URL = `/sites`
export const RECONCILIATION_URL = `/reconciliation`
export const AUDIT_URL = `/audit`
export const DEEP_DIVE_URL = '/deepDive'
export const DASHBOARD_URL = '/dashboard'

const YEAR_MONTH_OPTIONAL_PATH_PARAMS = `/:year?/:month?`

export const APP_URLS = {
  EMISSIONS: `${EMISSIONS_URL}/:id`,
  INBOX: INBOX_URL,
  INBOX_TAB: `${INBOX_URL}/${TAB_DETECTION_SUFFIX}`,
  INBOX_EMISSIONS_BY_SITE: `${INBOX_URL}/emissions/:siteId/:emissionId?`,
  WIP: WIP_URL,
  WIP_TAB: `${WIP_URL}/${TAB_DETECTION_SUFFIX}`,
  DEEP_DIVE: DEEP_DIVE_URL,
  SITES: SITES_URL,
  SITE_VIEW: `${SITES_URL}/:id`,
  RECONCILIATION_BASE: RECONCILIATION_URL,
  RECONCILIATION: `${RECONCILIATION_URL}${YEAR_MONTH_OPTIONAL_PATH_PARAMS}`,
  RECONCILIATION_DECISION_LOG: `${RECONCILIATION_URL}/decisions${YEAR_MONTH_OPTIONAL_PATH_PARAMS}`,
  RECONCILIATION_SITE_REPORT: `${RECONCILIATION_URL}/:siteId/:year/:month/:day?/:equipmentId?`,
  DASHBOARD: DASHBOARD_URL,
  AUDIT_BASE: AUDIT_URL,
  AUDIT: `${AUDIT_URL}${YEAR_MONTH_OPTIONAL_PATH_PARAMS}`,
}

export const ITEMS_PER_PAGE = 20

export const NO_VALUE_PLACEHOLDER = '--'

export const METHANE_OLD_ENTITY_NAMES = keymirror({
  deep_dive: null,
  reconciliation_overview: null,
  decisionLog: null,
  outliers: null,
  inbox_open_emissions: null,
  inbox_open_emissions_grouped: null,
  detection_wip: null,
  detection_wip_closed: null,
  audit: null,
  executive_dashboard: null,
})

/** Creating new entity names with prefix */
export const METHANE_ENTITY_NAMES = [
  ...Object.values(METHANE_OLD_ENTITY_NAMES),
  'site',
].reduce(
  (acc, entityName) => ({
    ...acc,
    // Giving a prefix to each entity
    [entityName]: makeMethaneAppEntityName(entityName),
  }),
  {} as typeof METHANE_OLD_ENTITY_NAMES & { site: string }
)

/** 14 Nov 2023: Hiding some unfinished features before the MVP release */
export const IS_FEATURE_ENABLED = {
  SITE_OUTLIERS: false,
  WIP_EVENT_ACTIVITY: false,
}
