import keymirror from 'keymirror'

import {
  DETECTION_TYPES,
  DETECTION_TYPE_ICONS_MAPPING,
} from 'app/MissionControlMethaneSolution/constants/detection'

export const VFB_EVENT_TYPE = 'VFB'

export const EMISSION_EVENT_TYPE_ICONS_MAPPING = {
  ...DETECTION_TYPE_ICONS_MAPPING,
  [VFB_EVENT_TYPE]: 'MethaneVFBIcon',
}

export const EMISSION_EVENT_TYPE_TOOLTIPS = {
  [DETECTION_TYPES.DETECTION_TYPE_CMS]: 'Continuous Monitoring System',
  [DETECTION_TYPES.DETECTION_TYPE_DRONE]: 'Drone',
  [DETECTION_TYPES.DETECTION_TYPE_OGI]: 'Optical Gas Imaging',
  [DETECTION_TYPES.DETECTION_TYPE_OWD]: 'On-site Walk Down',
  [DETECTION_TYPES.DETECTION_TYPE_FLYOVER]: 'Flyover',
  [DETECTION_TYPES.DETECTION_TYPE_SATELLITE]: 'Satellite',
  [DETECTION_TYPES.DETECTION_TYPE_MANUAL]: 'Manual',
  [DETECTION_TYPES.DETECTION_TYPE_UNKNOWN]: 'Unknown',
  [VFB_EVENT_TYPE]: 'VFB',
}

export const EMISSION_EVENT_ATTRIBUTION_TYPES = keymirror({
  SITE: null,
  EQUIPMENT: null,
})
