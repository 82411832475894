// libraries
import { ReactElement, useCallback, useMemo } from 'react'
import _ from 'lodash'

// constants
import {
  BUTTON_VARIANTS,
  BUTTON_ICON_POSITIONS,
} from 'components/common/Button'
import {
  BADGE_TYPES,
  ENTITIES,
  GALLERY_LIST_TYPES,
  GALLERY_LIST_TYPES_ICONS,
} from 'constants/common'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

import { useGalleryOptionsStorage } from 'hooks'

// components
import {
  SortButton,
  SearchBar,
  IconButton,
  Button,
  Badge,
  Dropdown,
  TableColumnToggler,
  TableDateRangeFilter,
} from 'components/common'

import type { Filters, OnFiltersChange, DateFilterOptions } from 'types/filter'
import type { OnGroupByChange } from 'types/gallery'
import type { Options, Payload, ListConditions } from 'types/common'
import type {
  ColumnsWithGroupable,
  ColumnOptions,
} from 'components/common/DataTable/useDataTableColumns'

import scss from './index.module.scss'

const ICON_SIZE = 16

type ToolboxProps = {
  entity: keyof typeof ENTITIES
  onChange: (v: ListConditions) => void
  onRefresh: () => void
  options: {
    hideFiltersButton?: boolean
    enableSearch: boolean
    enableListTypes: string[]
    sortOptions: Options
    displaySort: boolean
    searchBarPlaceholder: string
    changeOnBlur: boolean
    enableSearchFields: boolean
    enableSearchDebounce?: boolean
    searchFieldsLoading: boolean
    searchFieldsOptions: { value: string; label: string }[]
    defaultSearchField: string
    tableGroupableColumns: ColumnsWithGroupable
    enableDateFilter?: boolean
    dateFilterOptions?: DateFilterOptions
  }
  conditions: ListConditions
  filterValues?: Filters
  filtersCount?: number
  toggleShowFilters?: (v: boolean) => void
  isShowingFilters?: boolean
  columns?: ColumnOptions[]
  visibleColumns?: ColumnOptions[]
  setVisibleColumns?: (columns: ColumnOptions[]) => void
  onGroupByChange: OnGroupByChange
  onFiltersChange: OnFiltersChange
  renderAdditionalTools?: () => ReactElement
}

export const TABLE_GROUP_BY_OPTION = {
  label: 'None',
  value: 'default_none_table_group_by',
}

const Toolbox = ({
  entity,
  conditions,
  options,
  onChange,
  onRefresh,
  filterValues,
  filtersCount = 0,
  toggleShowFilters,
  isShowingFilters,
  columns,
  visibleColumns,
  setVisibleColumns,
  onGroupByChange,
  onFiltersChange,
  renderAdditionalTools,
}: ToolboxProps): ReactElement => {
  const {
    hideFiltersButton = false,
    enableSearch = true,
    enableListTypes = [GALLERY_LIST_TYPES.card, GALLERY_LIST_TYPES.table],
    sortOptions = [],
    searchBarPlaceholder,
    enableSearchFields = false,
    defaultSearchField,
    enableSearchDebounce,
    searchFieldsLoading = false,
    searchFieldsOptions = [],
    displaySort = true,
    tableGroupableColumns,
    enableDateFilter,
    dateFilterOptions,
  } = options

  const {
    sortField = _.get(sortOptions, '[0].value') || 'audit.updatedTime',
    ascOrder = true,
    listType = GALLERY_LIST_TYPES.card,
    tableGroupedBy,
  } = conditions

  useGalleryOptionsStorage({
    entity,
    listConditions: conditions,
    visibleColumns,
  })

  const onChangeConditions = useCallback(
    (payload: Payload) => {
      onChange({ ...conditions, ...payload })
    },
    [conditions, onChange]
  )

  const visibleListType = useMemo(() => {
    return _.without(enableListTypes, listType)
  }, [enableListTypes, listType])

  const groupableColumnsOptions = useMemo(
    () =>
      _.map(tableGroupableColumns, ({ key, header, field, groupByField }) => ({
        label: key ?? header,
        value: groupByField ?? field,
      })),
    [tableGroupableColumns]
  )

  const renderSearchBar = () =>
    enableSearch && (
      <SearchBar
        onChange={({ selectedFields, value }) => {
          onChangeConditions({
            search: value,
            selectedSearchFields: selectedFields,
            isSearching: !!value,
          })
        }}
        placeholder={searchBarPlaceholder}
        className='me-1'
        enableSearchFields={enableSearchFields}
        defaultSearchField={defaultSearchField}
        enableSearchDebounce={enableSearchDebounce}
        fieldsLoading={searchFieldsLoading}
        fieldsOptions={searchFieldsOptions}
      />
    )

  const renderDateFilter = () =>
    enableDateFilter &&
    !_.isEmpty(dateFilterOptions) && (
      <TableDateRangeFilter
        filterValues={filterValues}
        dateFilterOptions={dateFilterOptions}
        onFiltersChange={onFiltersChange}
      />
    )

  const renderSortBy = () =>
    !_.isEmpty(sortOptions) &&
    displaySort &&
    listType !== GALLERY_LIST_TYPES.table && (
      <SortButton
        className='mx-2'
        sortOptions={sortOptions}
        property={sortField}
        onChange={val => onChangeConditions({ sortField: val })}
        ascSortOrder={ascOrder}
        onOrderChange={val => onChangeConditions({ ascOrder: val })}
      />
    )

  const renderFilterToggle = () =>
    _.isFunction(toggleShowFilters) &&
    !hideFiltersButton && (
      <Button
        className={`${scss.toolbarButton} ${
          isShowingFilters ? '' : 'text-secondary'
        } p-2 me-2`}
        variant={BUTTON_VARIANTS.link}
        onClick={() => toggleShowFilters(!isShowingFilters)}
        icon='MdFilterAlt'
        iconPosition={BUTTON_ICON_POSITIONS.left}
      >
        <span>Filter</span>
        {filtersCount > 0 && (
          <Badge
            content={filtersCount}
            className='ms-2'
            type={BADGE_TYPES.primary}
          />
        )}
      </Button>
    )

  const renderListTypeSwither = () =>
    visibleListType.map(type => {
      return (
        <Button
          key={type}
          className={`${scss.toolbarButton} text-secondary p-2 me-2`}
          variant={BUTTON_VARIANTS.link}
          onClick={() => onChangeConditions({ listType: type })}
          icon={GALLERY_LIST_TYPES_ICONS[type]}
          iconPosition={BUTTON_ICON_POSITIONS.left}
        >
          {_.capitalize(type)} view
        </Button>
      )
    })

  const renderTableGroupBy = () =>
    !_.isEmpty(tableGroupableColumns) &&
    listType === GALLERY_LIST_TYPES.table && (
      <div className='d-flex align-items-enter me-2'>
        <Dropdown
          selectedValue={tableGroupedBy}
          toggleComponent={() => {
            return (
              <Button
                className={`${scss.toolbarButton} text-secondary p-2`}
                variant={BUTTON_VARIANTS.link}
                icon='TableGroupBy'
                iconPosition={BUTTON_ICON_POSITIONS.left}
              >
                <>
                  Group by{' '}
                  {_.find(groupableColumnsOptions, { value: tableGroupedBy })
                    ?.label || ''}
                </>
              </Button>
            )
          }}
          placement={TOOLTIP_PLACEMENT.bottom}
          options={
            tableGroupedBy
              ? [TABLE_GROUP_BY_OPTION, ...groupableColumnsOptions]
              : groupableColumnsOptions
          }
          onChange={(v: string) => {
            const newValue = v === TABLE_GROUP_BY_OPTION.value ? undefined : v
            onChangeConditions({ tableGroupedBy: newValue })
            onGroupByChange({ newTableGroupedBy: newValue, onFiltersChange })
          }}
        />
      </div>
    )

  const renderListRefetch = () =>
    onRefresh && (
      <IconButton
        icon='MdRefresh'
        className={scss.refreshBtn}
        size={ICON_SIZE}
        onClick={() => onRefresh()}
      />
    )

  const renderTableColumnToggler = () =>
    listType === GALLERY_LIST_TYPES.table &&
    !!columns?.length && (
      <TableColumnToggler
        className={`${scss.toolbarButton} me-2`}
        columns={columns}
        visibleColumns={visibleColumns as ColumnOptions[]}
        setVisibleColumns={
          setVisibleColumns as (selectedOptions: ColumnOptions[]) => void
        }
      />
    )

  return (
    <div className='d-flex align-items-center'>
      {/* Render the search always first because it changes its width on click */}
      {renderSearchBar()}
      {renderDateFilter()}
      {renderAdditionalTools?.()}
      {renderSortBy()}
      {renderFilterToggle()}
      {renderTableGroupBy()}
      {renderTableColumnToggler()}
      {renderListTypeSwither()}
      {renderListRefetch()}
    </div>
  )
}

Toolbox.defaultProps = {
  onRefresh: undefined,
  conditions: {},
  options: {},
  onGroupByChange: _.noop,
  onFiltersChange: _.noop,
}

export default Toolbox
