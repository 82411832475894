// libraries
import {
  useEffect,
  useState,
  useCallback,
  memo,
  CSSProperties,
  ReactElement,
} from 'react'
import _ from 'lodash'
import to from 'await-to-js'
import { useMountedState } from 'react-use'

// constants
import { PROFILE_WIDGET_TYPES } from 'constants/profile'
import {
  WIDGET_TYPES,
  ECHARTS_AXIS_TYPE,
  DEFAULT_CHART_PRIMARY_COLOUR,
} from 'constants/widget'
import { DATE_UNIT_TYPES } from 'constants/datetime'
import {
  PROPERTY_VARIABLE_FORMATS,
  PROPERTY_VARIABLE_TYPES,
} from 'constants/filter'
import { IMAGE_GALLERY_TYPES } from 'constants/common'

// utils
import { displayValue, switchcaseF, isRangeValid } from 'helpers/utils'
import {
  pomIsExpired,
  pomTimeInfo,
  pomLastInfo,
  pomColorInfo,
  pomBackgroundColorInfo,
  pomTagInfo,
} from 'helpers/pom'
import {
  getSeriesFeaturesDataForEcharts,
  getDatasetFeaturesDataForEcharts,
} from 'helpers/widget'
import { useBranding, useTimezone, useTrashable } from 'hooks'
import log from 'helpers/log'

// components
import {
  WidgetBase,
  NoAvailableWidget,
  GaugeWidget,
  LineWidget,
  BarWidget,
} from 'components/widget'
import { ImageGallery, ExternalLink } from 'components/common'

// types
import type { Timezone } from 'types/datetime'
import type {
  MapLayerProfileProperty,
  MapLayerData,
  GeojsonData,
  MapLayerProfileWidgetType,
} from 'types/map'
import type {
  DatasetMetadata,
  PropertyVariableType,
  PropertyFormatType,
} from 'types/common'

// style
import scss from './index.module.scss'

const pomInfo = ({
  row,
  observedPropertyName,
  datasetMetadata,
}: {
  row?: GeojsonData
  observedPropertyName?: string
  datasetMetadata?: DatasetMetadata
} = {}): {
  isExpired: boolean
  conditionalPomTimeInfo: {
    deltaTime: number
    deltaTimeHumanized: string
  } | null
  conditionalPomLastInfo: {
    lastDeltaDirection: string
    lastDeltaDirectionSuffix: string | undefined
  } | null
  conditionalPomColorInfo: {
    color: string
  } | null
  conditionalPomBackgroundColorInfo: { backgroundColor: string } | null
  conditionalPomTagInfo: { tag: string } | null
} => {
  const input = {
    row,
    observedPropertyName,
    datasetMetadata,
  }
  return {
    isExpired: pomIsExpired(input),
    conditionalPomTimeInfo: pomTimeInfo(input),
    conditionalPomLastInfo: pomLastInfo(input),
    conditionalPomColorInfo: pomColorInfo(input),
    conditionalPomBackgroundColorInfo: pomBackgroundColorInfo(input),
    conditionalPomTagInfo: pomTagInfo(input),
  }
}

const EMPTY_VALUE_PLACEHOLDER = '– –'

export const PropertyValueDisplay = memo(
  ({
    value,
    type = PROPERTY_VARIABLE_TYPES.string,
    format,
    valueStyle,
    timezone,
    tag,
    lastDeltaDirectionSuffix,
    breakLine = false,
    pinned,
    className,
  }: {
    value?: unknown
    type?: PropertyVariableType
    format?: PropertyFormatType
    valueStyle?: CSSProperties
    timezone?: Timezone
    tag?: string
    lastDeltaDirectionSuffix?: string
    breakLine?: boolean
    pinned?: boolean
    className?: string
  }): ReactElement => {
    const isEmptyValue =
      _.isNil(value) ||
      (_.includes(
        [
          PROPERTY_VARIABLE_TYPES.string,
          PROPERTY_VARIABLE_TYPES.array,
          PROPERTY_VARIABLE_TYPES.object,
        ],
        type
      ) &&
        _.isEmpty(value))

    const renderText = () => (
      <span style={valueStyle} className={`${scss.text} ${className}`}>
        {isEmptyValue
          ? EMPTY_VALUE_PLACEHOLDER
          : displayValue(value, timezone, true, breakLine)}
        {lastDeltaDirectionSuffix}
        {tag && <span className={scss.tagStyle}>{tag}</span>}
      </span>
    )

    const renderImage = () => {
      const imagesArray = _.compact(
        type === PROPERTY_VARIABLE_TYPES.string && _.isString(value)
          ? [value]
          : type === PROPERTY_VARIABLE_TYPES.array && _.isArray(value)
          ? (value as string[])
          : undefined
      )

      const newImagesArray = _.map(imagesArray, image => ({
        src: image,
      }))

      return (
        !_.isEmpty(imagesArray) && (
          <div style={{ height: '120px' }}>
            <ImageGallery
              images={newImagesArray}
              type={IMAGE_GALLERY_TYPES.column}
              enableModalCarousel={pinned}
            />
          </div>
        )
      )
    }

    const renderLink = () =>
      isEmptyValue ? (
        EMPTY_VALUE_PLACEHOLDER
      ) : (
        <ExternalLink link={value as string} />
      )

    return switchcaseF({
      [PROPERTY_VARIABLE_FORMATS.image]: renderImage,
      [PROPERTY_VARIABLE_FORMATS.url]: renderLink,
    })(renderText)(format)
  }
)

const PopupItem = ({
  property,
  featuresData,
  highlightList = [],
  datasetMetadata = {} as DatasetMetadata,
  object,
  pinned = false,
}: {
  property: MapLayerProfileProperty
  featuresData: MapLayerData
  object?: GeojsonData
  highlightList: []
  datasetMetadata?: DatasetMetadata
  pinned: boolean
}): ReactElement => {
  const registerPromise = useTrashable()
  const [popup, setPopup] = useState(<></>)
  const { timezone } = useTimezone()
  const {
    colour: { primary },
  } = useBranding()

  const isMounted = useMountedState()

  const renderWidgetList = useCallback(
    ({ label, key, value, format, type }: MapLayerProfileProperty) => {
      const isHighlighted = _.find(highlightList, ['property', label])

      const {
        isExpired,
        conditionalPomTimeInfo,
        conditionalPomLastInfo,
        conditionalPomColorInfo,
        conditionalPomBackgroundColorInfo,
        conditionalPomTagInfo,
      } = pomInfo({
        row: object,
        observedPropertyName: _.defaultTo(key, ''),
        datasetMetadata,
      })

      const style = {
        color: isHighlighted ? primary : '#f3f3f3cc',
        opacity: isExpired ? 0.5 : undefined,
        fontSize: isHighlighted ? '16px' : '13px',
      }

      const valueStyle = {
        color: isHighlighted
          ? primary
          : _.get(conditionalPomColorInfo, 'color', '#f3f3f3cc'),
        opacity: isExpired ? 0.5 : undefined,
        fontSize: isHighlighted ? '16px' : '13px',
        backgroundColor: _.get(
          conditionalPomBackgroundColorInfo,
          'backgroundColor',
          'inherit'
        ),
        padding: _.has(conditionalPomBackgroundColorInfo, 'backgroundColor')
          ? '0 3px 3px 3px'
          : 'inherit',
      }

      const { lastDeltaDirectionSuffix = '' } = conditionalPomLastInfo || {}
      const { tag } = conditionalPomTagInfo || {}

      return (
        <div
          className={scss.row}
          {...(conditionalPomTimeInfo && {
            title: `Delta from time: ${conditionalPomTimeInfo.deltaTimeHumanized}`,
          })}
        >
          <div className={scss.label} style={style}>
            {label}
          </div>
          <div className={scss.value}>
            <PropertyValueDisplay
              value={value}
              format={format}
              type={type}
              valueStyle={valueStyle}
              timezone={timezone}
              tag={tag}
              lastDeltaDirectionSuffix={lastDeltaDirectionSuffix}
              pinned={pinned}
            />
          </div>
        </div>
      )
    },
    [datasetMetadata, highlightList, object, pinned, primary, timezone]
  )

  const renderWidgetNumeric = useCallback(
    ({ label, value }: MapLayerProfileProperty) => {
      return (
        <WidgetBase title={label}>
          {_.isNil(value) ? (
            <NoAvailableWidget widgetType={WIDGET_TYPES.numeric} />
          ) : (
            <div className={scss.center}>{displayValue(value)}</div>
          )}
        </WidgetBase>
      )
    },
    []
  )

  const renderWidgetGauge = useCallback(
    ({ label, value, range, widgetRange }: MapLayerProfileProperty) => {
      const defaultRange = isRangeValid(range)
        ? range
        : [_.floor(value / 2), _.floor(value * 2)]

      return (
        <WidgetBase title={label}>
          {_.isNil(value) ? (
            <NoAvailableWidget widgetType={WIDGET_TYPES.gauge} />
          ) : (
            <GaugeWidget
              value={_.toNumber(value)}
              yAxisRange={
                isRangeValid(widgetRange) ? widgetRange : defaultRange
              }
              colour={DEFAULT_CHART_PRIMARY_COLOUR}
              size='small'
            />
          )}
        </WidgetBase>
      )
    },
    []
  )

  const renderWidgetLine = useCallback(
    async ({ label, key, range, widgetRange }: MapLayerProfileProperty) => {
      const yAxisPropertyName = key
      const widgetType = WIDGET_TYPES.line
      const [err, series] = await to(
        registerPromise(
          getSeriesFeaturesDataForEcharts({
            geojsonRows: featuresData,
            type: widgetType,
            yAxisPropertyName,
            timezone,
          })
        )
      )

      return (
        <WidgetBase title={label}>
          {err || _.isEmpty(series) ? (
            <NoAvailableWidget widgetType={widgetType} />
          ) : (
            <LineWidget
              series={series}
              colour={DEFAULT_CHART_PRIMARY_COLOUR}
              yAxisRange={isRangeValid(widgetRange) ? widgetRange : range}
              yAxisPropertyName={yAxisPropertyName}
              expanded={false}
              timeSeries
              timezone={timezone}
              size='small'
            />
          )}
        </WidgetBase>
      )
    },
    [featuresData, registerPromise, timezone]
  )

  const renderWidgetBar = useCallback(
    async ({ key, label, range, widgetRange }: MapLayerProfileProperty) => {
      const yAxisValuePropertyName = key
      const dataset = await registerPromise(
        getDatasetFeaturesDataForEcharts({
          geojsonRows: featuresData,
          xAxisLabelPropertyName: 'time',
          yAxisValuePropertyName,
          timezone,
        })
      )

      return (
        <WidgetBase title={label}>
          {dataset ? (
            <BarWidget
              dataset={dataset}
              colour={DEFAULT_CHART_PRIMARY_COLOUR}
              yAxisValuePropertyName={yAxisValuePropertyName}
              yAxisRange={isRangeValid(widgetRange) ? widgetRange : range}
              expanded={false}
              xAxisType={ECHARTS_AXIS_TYPE.time}
              period={DATE_UNIT_TYPES.minutes}
              timezone={timezone}
              size='small'
            />
          ) : (
            <NoAvailableWidget widgetType={WIDGET_TYPES.bar} />
          )}
        </WidgetBase>
      )
    },
    [featuresData, registerPromise, timezone]
  )

  /**
   * for widgets which will display the single value
   */
  const renderSingleValueItem = useCallback(
    (widgetType: MapLayerProfileWidgetType) => {
      const { isVisible = true } = property

      const result = isVisible
        ? switchcaseF({
            [PROFILE_WIDGET_TYPES.list]: () => renderWidgetList(property),
            [PROFILE_WIDGET_TYPES.numeric]: () => renderWidgetNumeric(property),
            [PROFILE_WIDGET_TYPES.gauge]: () => renderWidgetGauge(property),
          })(() => renderWidgetList(property))(widgetType)
        : null
      if (isMounted()) {
        setPopup(result)
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      isMounted(),
      property,
      renderWidgetGauge,
      renderWidgetList,
      renderWidgetNumeric,
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  )

  /**
   * for widgets which will display multiple values
   */
  const renderMultipleValueItem = useCallback(
    (widgetType: MapLayerProfileWidgetType) => {
      if (_.isEmpty(featuresData)) return
      const result =
        widgetType === PROFILE_WIDGET_TYPES.line
          ? renderWidgetLine(property)
          : renderWidgetBar(property)
      if (result) {
        Promise.resolve(result)
          .then(value => {
            if (isMounted() && value) {
              setPopup(value)
            }
          })
          .catch(error => {
            // if the error is Object(...) is not a function, which means the
            // worker is not loaded correctly (the workerize-loader solution).
            // It usually only happens during the development. The solution is
            // either modifying the helpers/worker.js (i.e., add comments and
            // space) or restart the application, which forces the worker to be
            // loaded again.
            // For more information, please refer to https://github.com/developit/workerize-loader/issues/24
            log.error('renderPopupItem', error)
          })
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [featuresData, isMounted(), property, renderWidgetBar, renderWidgetLine]
    /* eslint-enable react-hooks/exhaustive-deps */
  )

  const renderPopupItem = useCallback(
    ({ widgetType }: { widgetType: MapLayerProfileWidgetType }) => {
      if (
        widgetType === PROFILE_WIDGET_TYPES.line ||
        widgetType === PROFILE_WIDGET_TYPES.bar
      ) {
        renderMultipleValueItem(widgetType)
      } else {
        renderSingleValueItem(widgetType)
      }
    },
    [renderMultipleValueItem, renderSingleValueItem]
  )

  useEffect(() => {
    renderPopupItem(property)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property])

  return popup
}

export default PopupItem
