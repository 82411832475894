import { ReactElement } from 'react'

import { IconButton, Tooltip } from 'components/common'

import { TOOLTIP_PLACEMENT } from 'constants/settings'
import { THEMES } from 'constants/colour'
import { EXTERNAL_URL_LABEL } from 'constants/common'

import ExternalLinkPopOver from './ExternalLinkPopover'

const ExternalLink = ({ link }: { link: string }): ReactElement => (
  <Tooltip
    placement={TOOLTIP_PLACEMENT.bottom}
    trigger={['hover']}
    theme={THEMES.light}
    overlayClassName={THEMES.light}
    overlay={<ExternalLinkPopOver externalUrl={link} />}
    showArrow={false}
  >
    <a href={link} target='_blank' rel='noopener noreferrer nofollow'>
      <IconButton icon='GoLinkExternal' /> {EXTERNAL_URL_LABEL}
    </a>
  </Tooltip>
)

export default ExternalLink
