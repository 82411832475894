// libraries
import { ReactElement } from 'react'
import { ColumnBodyOptions } from 'primereact/column'
import _ from 'lodash'

// constants
import { DATE_FORMAT } from 'constants/datetime'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// utils
import { useTimezone } from 'hooks'
import { getFromNow } from 'helpers/datetime'
import { displayTime } from 'helpers/utils'

// components
import { Tooltip } from 'components/common'

interface TemplateProps {
  rowData: unknown
  options: ColumnBodyOptions
}

const Template = ({ rowData, options }: TemplateProps): ReactElement | null => {
  const { timezone } = useTimezone()
  const time = _.get(rowData, options.field)

  if (!time) {
    return null
  }
  const tooltipContent = getFromNow(time)
  const displayTimeOptions = {
    datetime: time,
    timezone,
    addFromNow: false,
    displayLabel: false,
    timeFormat: DATE_FORMAT,
  }
  const formattedTime = displayTime(displayTimeOptions)

  return (
    <Tooltip
      placement={TOOLTIP_PLACEMENT.top}
      trigger={['hover']}
      overlay={<span>{tooltipContent}</span>}
    >
      <span>{formattedTime}</span>
    </Tooltip>
  )
}

const DateTemplate = (
  rowData: unknown,
  options: ColumnBodyOptions
): ReactElement => <Template rowData={rowData} options={options} />

export default DateTemplate
