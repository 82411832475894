import React, { useMemo, useCallback } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

// utils
import { displayValue } from 'helpers/utils'
import {
  getSimpleAggregatedResultValueLabel,
  getGaugeValueRange,
} from 'helpers/widget'
import { colourArrToRgbaStr } from 'helpers/colour'

// constants
import { DEFAULT_WIDGET_COLOUR, WIDGET_TYPES } from 'constants/widget'
import { DEFAULT_AGGREGATION_KEY_TYPE } from 'constants/aggregation'
import { THEMES } from 'constants/colour'
import { ASSET_PROFILE_MEDIA_TYPES } from 'constants/assets'
import { NO_DATA_PLACEHOLDER } from 'constants/common'

// components
import { BaseWidgetWithAssetState } from 'components/assets/assetsProfile/widgets/BaseWidget'
import { GaugeWidget, NoAvailableWidget } from 'components/widget'

const DisplaySingleValue = ({
  properties,
  propertiesMetadata,
  overridePropertyTitle,
  timezone,
  property,
  colour = DEFAULT_WIDGET_COLOUR,
  theme,
}) => {
  const overrideTitle = useMemo(() => {
    const { displayName } = _.find(propertiesMetadata, { name: property }) || {}
    return overridePropertyTitle || displayName || property
  }, [overridePropertyTitle, propertiesMetadata, property])

  const data = useMemo(() => {
    return _.toNumber(displayValue(properties[property], timezone, false) || '')
  }, [properties, property, timezone])

  const yAxisRange = useMemo(() => {
    return getGaugeValueRange(undefined, data)
  }, [data])

  return _.isNil(data) ? (
    <NoAvailableWidget
      widgetType={WIDGET_TYPES.gauge}
      content={NO_DATA_PLACEHOLDER}
    />
  ) : (
    <GaugeWidget
      {...(colour ? { colour: colourArrToRgbaStr(colour) } : {})}
      value={data}
      label={overrideTitle}
      yAxisRange={yAxisRange}
      theme={theme}
    />
  )
}

DisplaySingleValue.propTypes = {
  properties: PropTypes.shape({}).isRequired,
  propertiesMetadata: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  overridePropertyTitle: PropTypes.string,
  timezone: PropTypes.string,
  colour: PropTypes.arrayOf(PropTypes.number),
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
  property: PropTypes.string,
}

DisplaySingleValue.defaultProps = {
  overridePropertyTitle: undefined,
  colour: DEFAULT_WIDGET_COLOUR,
  theme: THEMES.dark,
  timezone: undefined,
  property: undefined,
}

const DisplayAssetSingleValue = ({
  value,
  overridePropertyTitle,
  timezone,
  property,
  colour = DEFAULT_WIDGET_COLOUR,
  theme,
}) => {
  const { properties = {} } = value || {}
  const { properties: propertiesMetadata = [] } = value?.assetProfile || {}

  return (
    <DisplaySingleValue
      properties={properties}
      propertiesMetadata={propertiesMetadata}
      overridePropertyTitle={overridePropertyTitle}
      property={property}
      timezone={timezone}
      colour={colour}
      theme={theme}
    />
  )
}

DisplayAssetSingleValue.propTypes = {
  value: PropTypes.shape({
    properties: PropTypes.shape({}),
    assetProfile: PropTypes.shape({
      properties: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  overridePropertyTitle: PropTypes.string,
  timezone: PropTypes.string,
  colour: PropTypes.arrayOf(PropTypes.number),
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
  property: PropTypes.string,
}

DisplayAssetSingleValue.defaultProps = {
  overridePropertyTitle: undefined,
  colour: DEFAULT_WIDGET_COLOUR,
  theme: THEMES.dark,
  timezone: undefined,
  property: undefined,
}

const DisplayRelatedAssetSingleValue = ({
  value,
  overridePropertyTitle,
  timezone,
  property,
  colour = DEFAULT_WIDGET_COLOUR,
  theme,
}) => {
  const { properties } = value?.related?.asset || {}
  const { properties: propertiesMetadata = [] } = value?.assetProfile || {}

  return (
    <DisplaySingleValue
      properties={properties}
      propertiesMetadata={propertiesMetadata}
      overridePropertyTitle={overridePropertyTitle}
      property={property}
      timezone={timezone}
      colour={colour}
      theme={theme}
    />
  )
}

DisplayRelatedAssetSingleValue.propTypes = {
  value: PropTypes.shape({
    related: PropTypes.shape({
      asset: PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
        properties: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
    assetProfile: PropTypes.shape({
      properties: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  overridePropertyTitle: PropTypes.string,
  timezone: PropTypes.string,
  colour: PropTypes.arrayOf(PropTypes.number),
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
  property: PropTypes.string,
}

DisplayRelatedAssetSingleValue.defaultProps = {
  overridePropertyTitle: undefined,
  colour: DEFAULT_WIDGET_COLOUR,
  theme: THEMES.dark,
  timezone: undefined,
  property: undefined,
}

const DisplayRelatedAssetsValues = ({
  value,
  colour = DEFAULT_WIDGET_COLOUR,
  overridePropertyTitle,
  relatedAssetAggregation = DEFAULT_AGGREGATION_KEY_TYPE,
  relatedAssetAggregationValueRange,
  theme,
}) => {
  const { assets } = value?.related || {}

  const {
    value: data,
    label,
    widgetColour,
  } = getSimpleAggregatedResultValueLabel({
    geojsonRows: assets,
    aggregation: relatedAssetAggregation,
    overridePropertyTitle,
    colour,
  })

  const number = useMemo(() => {
    return _.toNumber(data)
  }, [data])

  return _.isNil(data) ? (
    <NoAvailableWidget
      widgetType={WIDGET_TYPES.gauge}
      content={NO_DATA_PLACEHOLDER}
    />
  ) : (
    <GaugeWidget
      yAxisRange={getGaugeValueRange(relatedAssetAggregationValueRange, number)}
      colour={widgetColour}
      value={number}
      label={label}
      theme={theme}
    />
  )
}

DisplayRelatedAssetsValues.propTypes = {
  value: PropTypes.shape({
    related: PropTypes.shape({
      assets: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          displayName: PropTypes.string,
          properties: PropTypes.arrayOf(PropTypes.shape({})),
        })
      ),
    }),
  }).isRequired,
  overridePropertyTitle: PropTypes.string,
  relatedAssetAggregation: PropTypes.shape({}),
  relatedAssetAggregationValueRange: PropTypes.arrayOf(PropTypes.number),
  colour: PropTypes.arrayOf(PropTypes.number),
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
}

DisplayRelatedAssetsValues.defaultProps = {
  overridePropertyTitle: undefined,
  colour: DEFAULT_WIDGET_COLOUR,
  theme: THEMES.dark,
  relatedAssetAggregation: DEFAULT_AGGREGATION_KEY_TYPE,
  relatedAssetAggregationValueRange: [],
}

const AssetGaugeWidget = ({
  assetId,
  settings,
  theme,
  name,
  onWidgetLoad,
  mediaType,
}) => {
  const isPrintable = useMemo(
    () => mediaType === ASSET_PROFILE_MEDIA_TYPES.PRINTABLE,
    [mediaType]
  )

  const render = useCallback(
    props => {
      const {
        relatedAssetRelationshipId,
        overridePropertyTitle,
        relatedAssetAggregation,
        relatedAssetAggregationValueRange,
        property,
        colour,
      } = settings

      const sharedProps = {
        ...props,
        property,
        overridePropertyTitle,
        colour,
        theme,
      }

      return relatedAssetRelationshipId ? (
        <DisplayRelatedAssetsValues
          {...sharedProps}
          relatedAssetRelationshipId={relatedAssetRelationshipId}
          relatedAssetAggregation={relatedAssetAggregation}
          relatedAssetAggregationValueRange={relatedAssetAggregationValueRange}
        />
      ) : (
        <DisplayAssetSingleValue {...sharedProps} />
      )
    },
    [settings, theme]
  )
  return (
    <BaseWidgetWithAssetState
      assetId={assetId}
      settings={settings}
      render={render}
      theme={theme}
      name={name}
      onWidgetLoad={onWidgetLoad}
      isPrintable={isPrintable}
    />
  )
}

AssetGaugeWidget.propTypes = {
  assetId: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    assetProfileId: PropTypes.string.isRequired,
    relatedAssetRelationshipId: PropTypes.string,
    relatedAssetProfileId: PropTypes.string,
    overridePropertyTitle: PropTypes.string,
    relatedAssetAggregation: PropTypes.shape({}),
    relatedAssetAggregationValueRange: PropTypes.arrayOf(PropTypes.number),
    property: PropTypes.string,
    colour: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
  name: PropTypes.string,
  onWidgetLoad: PropTypes.func,
  mediaType: PropTypes.string,
}

AssetGaugeWidget.defaultProps = {
  theme: THEMES.dark,
  name: undefined,
  onWidgetLoad: undefined,
}

export default AssetGaugeWidget
