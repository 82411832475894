import React, { useRef, useMemo, useCallback } from 'react'
import { useEcharts } from 'hooks'
import PropTypes from 'prop-types'
import _ from 'lodash'

// constants
import { THEMES } from 'constants/colour'
import {
  DEFAULT_WIDGET_SCATTER_SYMBOL,
  DEFAULT_CHART_TOOLTIP_OPTIONS,
  DEFAULT_CHART_PRIMARY_COLOUR,
  ECHARTS_AXIS_TYPE,
  DEFAULT_WIDGET_COLOUR_PALETTE,
  DEFAULT_DARK_THEME_CHART_TEXT_COLOUR,
  DEFAULT_DARK_THEME_CHART_SECONDARY_COLOUR,
  DEFAULT_LIGHT_THEME_CHART_TEXT_COLOUR,
  DEFAULT_LIGHT_THEME_CHART_SECONDARY_COLOUR,
} from 'constants/widget'

// utils
import { displayValue, getRangeMinMaxObj } from 'helpers/utils'
import { isLightTheme } from 'helpers/colour'
import { getWidgetGrid } from 'helpers/widget'

const DEFAULT_VISUAL_MAP = { show: false }

const ScatterWidget = ({
  dataset,
  colour,
  symbolSize,
  yAxisRange,
  xAxisRange,
  xAxisType,
  yAxisType,
  xAxisPropertyName,
  yAxisPropertyName,
  timezone,
  size,
  selectedDateTimeRange,
  theme,
  ...rest
}) => {
  const chartColour = useMemo(
    () => (colour ? [colour] : DEFAULT_WIDGET_COLOUR_PALETTE),
    [colour]
  )

  const lightTheme = useMemo(() => isLightTheme(theme), [theme])

  const chartRef = useRef()

  const dimensions = useMemo(() => {
    return _.get(dataset, 'source[0]')
  }, [dataset])

  const labels = useMemo(() => {
    return dimensions ? _.without(dimensions, 'geometry') : []
  }, [dimensions])

  const axisSelectedTimeRange = useMemo(
    () => ({
      ...(selectedDateTimeRange
        ? { min: selectedDateTimeRange.start, max: selectedDateTimeRange.end }
        : {}),
    }),
    [selectedDateTimeRange]
  )

  const getAxisMinMax = useCallback(
    (axisType, axisRange) => {
      return axisType === ECHARTS_AXIS_TYPE.time
        ? { ...axisSelectedTimeRange }
        : getRangeMinMaxObj(axisRange)
    },
    [axisSelectedTimeRange]
  )

  const xAxisMinMax = useMemo(
    () => getAxisMinMax(xAxisType, xAxisRange),
    [getAxisMinMax, xAxisRange, xAxisType]
  )

  const yAxisMinMax = useMemo(
    () => getAxisMinMax(yAxisType, yAxisRange),
    [getAxisMinMax, yAxisRange, yAxisType]
  )

  const getVisualMap = useCallback(
    ({ expanded }) => {
      let [verticalVisualMap, horizontalVisualMap] = [
        DEFAULT_VISUAL_MAP,
        DEFAULT_VISUAL_MAP,
      ]

      if (dimensions) {
        const show = !!expanded
        const sharedProps = {
          show,
          textStyle: { color: '#fff' },
        }

        verticalVisualMap = {
          ...sharedProps,
          ...yAxisMinMax,
          dimension: _.indexOf(dimensions, yAxisPropertyName),
          orient: 'vertical',
          right: 10,
          top: 'center',
        }

        horizontalVisualMap = {
          ...sharedProps,
          ...xAxisMinMax,
          dimension: _.indexOf(dimensions, xAxisPropertyName),
          orient: 'horizontal',
          top: 0,
          left: 'center',
        }
      }

      return yAxisType === ECHARTS_AXIS_TYPE.value
        ? verticalVisualMap
        : xAxisType === ECHARTS_AXIS_TYPE.value
        ? horizontalVisualMap
        : DEFAULT_VISUAL_MAP
    },
    [
      dimensions,
      xAxisMinMax,
      xAxisPropertyName,
      xAxisType,
      yAxisMinMax,
      yAxisPropertyName,
      yAxisType,
    ]
  )

  const getOptions = useCallback(
    ({ expanded } = {}) => {
      const sharedAxisOptions = {
        axisLine: {
          lineStyle: {
            color: lightTheme
              ? DEFAULT_LIGHT_THEME_CHART_SECONDARY_COLOUR
              : DEFAULT_DARK_THEME_CHART_SECONDARY_COLOUR,
          },
        },
        splitLine: {
          show: false,
        },
      }

      const visualMap = getVisualMap({ expanded })

      const xAxis = {
        ...sharedAxisOptions,
        ...xAxisMinMax,
        type: xAxisType,
        name: xAxisPropertyName,
        axisLabel: {
          color: lightTheme
            ? DEFAULT_LIGHT_THEME_CHART_TEXT_COLOUR
            : DEFAULT_DARK_THEME_CHART_TEXT_COLOUR,
          ...(expanded && xAxisType === ECHARTS_AXIS_TYPE.category
            ? { rotate: 50, interval: 0 }
            : {}),
        },
        axisTick: {
          show: false,
        },
      }

      const yAxis = {
        ...sharedAxisOptions,
        ...yAxisMinMax,
        type: yAxisType,
        name: yAxisPropertyName,
        axisLabel: {
          color: lightTheme
            ? DEFAULT_LIGHT_THEME_CHART_TEXT_COLOUR
            : DEFAULT_DARK_THEME_CHART_TEXT_COLOUR,
          // let echarts handle the time when yAxisType is time
          ...(yAxisType === ECHARTS_AXIS_TYPE.time
            ? {}
            : {
                formatter: displayValue,
              }),
        },
        splitNumber: expanded ? 5 : 1,
      }

      const color = {
        color: chartColour || DEFAULT_CHART_PRIMARY_COLOUR,
        // visualMap
        ...(colour ? {} : { visualMap }),
      }

      const tooltip = {
        formatter({ value, dimensionNames }) {
          const result = _(dimensionNames)
            .map(dimension => {
              return `${dimension}: ${displayValue(
                value[dimension],
                timezone,
                false
              )}`
            })
            .compact()
            .join('<br />')
          return result
        },
        ...DEFAULT_CHART_TOOLTIP_OPTIONS,
      }

      const series = [
        {
          type: 'scatter',
          symbolSize: expanded ? symbolSize * 2.5 : symbolSize,
          encode: {
            x: xAxisPropertyName,
            y: yAxisPropertyName,
            tooltip: labels,
          },
        },
      ]

      const grid = getWidgetGrid({ expanded })

      return {
        ...color,
        tooltip,
        grid,
        xAxis,
        yAxis,
        series,
        dataset,
        animation: false,
        // https://echarts.apache.org/en/option.html#useUTC
        useUTC: !timezone,
      }
    },
    [
      chartColour,
      colour,
      dataset,
      getVisualMap,
      labels,
      lightTheme,
      symbolSize,
      timezone,
      xAxisMinMax,
      xAxisPropertyName,
      xAxisType,
      yAxisMinMax,
      yAxisPropertyName,
      yAxisType,
    ]
  )

  const { style } = useEcharts({
    ...rest,
    chartRef,
    getOptions,
    size,
  })

  return <div ref={chartRef} style={style} />
}

ScatterWidget.propTypes = {
  dataset: PropTypes.shape({}).isRequired,
  colour: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.string,
  ]),
  symbolSize: PropTypes.number,
  yAxisRange: PropTypes.arrayOf(PropTypes.number),
  xAxisRange: PropTypes.arrayOf(PropTypes.number),
  xAxisType: PropTypes.string,
  yAxisType: PropTypes.string,
  xAxisPropertyName: PropTypes.string.isRequired,
  yAxisPropertyName: PropTypes.string.isRequired,
  timezone: PropTypes.string,
  size: PropTypes.string,
  selectedDateTimeRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
}

ScatterWidget.defaultProps = {
  colour: DEFAULT_WIDGET_COLOUR_PALETTE,
  symbolSize: DEFAULT_WIDGET_SCATTER_SYMBOL,
  yAxisRange: [],
  xAxisRange: [],
  xAxisType: ECHARTS_AXIS_TYPE.category,
  yAxisType: ECHARTS_AXIS_TYPE.category,
  timezone: undefined,
  size: 'regular',
  selectedDateTimeRange: undefined,
  theme: THEMES.dark,
}

const ScatterWidgetContainer = props => {
  const { dataset } = props
  return _.get(dataset, 'source[0]') ? <ScatterWidget {...props} /> : <></>
}

ScatterWidgetContainer.propTypes = {
  dataset: PropTypes.shape({}).isRequired,
}

export default ScatterWidgetContainer
