import React, { memo, useMemo, useCallback, useState } from 'react'
import { useMount } from 'react-use'
import PropTypes from 'prop-types'
import _ from 'lodash'

// utils
import { getWidgetBarOptions } from 'components/widget/common/widgetRenderHelper/bar'
import { getPropertyOptions } from 'components/assets/assetsProfile/hooks/useBaseAssetWidgetPanel'
import { displayValue } from 'helpers/utils'

// constants
import { DEFAULT_WIDGET_COLOUR, WIDGET_TYPES } from 'constants/widget'
import { THEMES } from 'constants/colour'
import { ASSET_PROFILE_MEDIA_TYPES } from 'constants/assets'
import { NO_DATA_PLACEHOLDER } from 'constants/common'

// components
import BarWidget from 'components/widget/BarWidget'
import { BaseWidgetWithAssetState } from 'components/assets/assetsProfile/widgets/BaseWidget'
import NoAvailableWidget from 'components/widget/common/NoAvailableWidget'

const DisplayAssetSingleValue = ({
  value,
  timezone,
  xAxisPropertyName,
  yAxisPropertyName,
  yAxisRange,
  colour = DEFAULT_WIDGET_COLOUR,
  theme,
}) => {
  const { properties = {} } = value || {}

  const dataset = useMemo(() => {
    return !_.isNil(properties[yAxisPropertyName])
      ? {
          dimensions: [xAxisPropertyName, yAxisPropertyName],
          source: [
            [
              properties[xAxisPropertyName],
              displayValue(properties[yAxisPropertyName], timezone, false) ||
                '',
            ],
          ],
        }
      : {}
  }, [properties, timezone, xAxisPropertyName, yAxisPropertyName])

  return _.isEmpty(dataset) ? (
    <NoAvailableWidget
      widgetType={WIDGET_TYPES.bar}
      content={NO_DATA_PLACEHOLDER}
    />
  ) : (
    <BarWidget
      {...(yAxisRange && { yAxisRange })}
      dataset={dataset}
      colour={colour}
      xAxisLabelPropertyName={xAxisPropertyName}
      yAxisValuePropertyName={yAxisPropertyName}
      yAxisRange={yAxisRange}
      timezone={timezone}
      theme={theme}
    />
  )
}

DisplayAssetSingleValue.propTypes = {
  value: PropTypes.shape({}).isRequired,
  timezone: PropTypes.string,
  settings: PropTypes.shape({}).isRequired,
  xAxisPropertyName: PropTypes.string,
  yAxisPropertyName: PropTypes.string,
  yAxisRange: PropTypes.arrayOf(PropTypes.number),
  colour: PropTypes.arrayOf(PropTypes.number),
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
}

DisplayAssetSingleValue.defaultProps = {
  timezone: undefined,
  yAxisRange: undefined,
  colour: DEFAULT_WIDGET_COLOUR,
  xAxisPropertyName: undefined,
  yAxisPropertyName: undefined,
  theme: THEMES.dark,
}

const DisplayRelatedAssetsValues = ({ value, timezone, settings, theme }) => {
  const { assets } = value?.related || {}
  const { properties } = value?.assetProfile || {}

  const [props, setProps] = useState(null)
  const { dataset, options } = props || {}

  const propertyOptions = useMemo(
    () => getPropertyOptions(properties),
    [properties]
  )

  useMount(() => {
    const getProps = async () => {
      const { type } = settings
      const result = await getWidgetBarOptions({
        style: settings[type],
        widgetData: assets,
        dataMerge: false,
        timezone,
        propertyOptions,
        barType: settings.type,
        xAxisPropertyPath: '',
        xAxisLabelPropertyName: 'displayName',
      })
      setProps(result)
    }
    getProps()
  })

  return _.isEmpty(dataset) || _.isEmpty(options) ? (
    <NoAvailableWidget
      widgetType={WIDGET_TYPES.bar}
      content={NO_DATA_PLACEHOLDER}
    />
  ) : (
    <BarWidget dataset={dataset} {...options} theme={theme} />
  )
}

DisplayRelatedAssetsValues.propTypes = {
  value: PropTypes.shape({
    related: PropTypes.shape({
      assets: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          displayName: PropTypes.string,
          properties: PropTypes.arrayOf(PropTypes.shape({})),
        })
      ),
    }),
    assetProfile: PropTypes.shape({
      properties: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  timezone: PropTypes.string,
  settings: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
}

DisplayRelatedAssetsValues.defaultProps = {
  timezone: undefined,
  theme: THEMES.dark,
}

const AssetBarWidget = memo(
  ({ assetId, settings, theme, name, onWidgetLoad, mediaType }) => {
    const render = useCallback(
      props => {
        const { relatedAssetRelationshipId } = settings
        const Component = relatedAssetRelationshipId
          ? DisplayRelatedAssetsValues
          : DisplayAssetSingleValue
        return <Component {...props} settings={settings} theme={theme} />
      },
      [settings, theme]
    )

    const isPrintable = useMemo(
      () => mediaType === ASSET_PROFILE_MEDIA_TYPES.PRINTABLE,
      [mediaType]
    )

    return (
      <BaseWidgetWithAssetState
        assetId={assetId}
        settings={settings}
        render={render}
        theme={theme}
        name={name}
        onWidgetLoad={onWidgetLoad}
        isPrintable={isPrintable}
      />
    )
  }
)

AssetBarWidget.propTypes = {
  assetId: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    assetProfileId: PropTypes.string.isRequired,
    relatedAssetRelationshipId: PropTypes.string,
    relatedAssetProfileId: PropTypes.string,
    properties: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
  name: PropTypes.string,
  onWidgetLoad: PropTypes.func,
  mediaType: PropTypes.string.isRequired,
}

AssetBarWidget.defaultProps = {
  theme: THEMES.dark,
  name: undefined,
  onWidgetLoad: undefined,
}

export default AssetBarWidget
