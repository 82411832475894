import { ReactElement } from 'react'
import _ from 'lodash'
import to from 'await-to-js'
// constants
import { BUTTON_VARIANTS } from 'components/common/Button'

// components
import { Button } from 'components/common'

// utils
import AuthService from 'services/authentication'
import { showCrudResponseMessage } from 'helpers/message'
import { displayTime } from 'helpers/utils'
import { useTimezone } from 'hooks'
import { useAuthStateValue } from 'contexts'

import type { AuthDevice } from 'types/auth'

import scss from './index.module.scss'

const getDisplayTime = ({
  datetime,
  timezone,
}: {
  datetime?: string
  timezone?: string
}) => {
  return datetime
    ? displayTime({
        datetime,
        timezone,
        addFromNow: false,
        displayLabel: false,
        timeFormat: 'll',
      })
    : ''
}

const TrustedDevices = ({
  devices,
}: {
  devices?: AuthDevice[]
}): ReactElement => {
  const { timezone } = useTimezone()
  const { setCurrentUser, currentUser } = useAuthStateValue()

  return (
    <ul>
      {_(devices)
        .filter({ remembered: true })
        .map(
          ({ deviceKey, name, createdAt, lastSignedInAt, lastIpAddress }) => {
            const deviceName = name
            const location = lastIpAddress
            return (
              <li
                key={deviceKey}
                className={`d-flex justify-content-between ${scss.deviceItem}`}
              >
                <div>
                  <div>{deviceName || 'Unknown device'}</div>
                  <div className='text-secondary smallText'>
                    {location
                      ? _.compact([
                          location,
                          getDisplayTime({
                            datetime: lastSignedInAt,
                            timezone,
                          }),
                        ]).join(' - ')
                      : ''}
                  </div>
                  <div className='text-secondary smallText'>{`First sign-in: ${getDisplayTime(
                    {
                      datetime: createdAt,
                      timezone,
                    }
                  )}`}</div>
                </div>
                <div>
                  <Button
                    variant={BUTTON_VARIANTS.link}
                    onClick={async () => {
                      const status = 'Forget'
                      const entity = 'Device'
                      const [err, success] = await to(
                        AuthService.forgetDevice(deviceKey, currentUser.email)
                      )
                      const error = success ? undefined : err || { message: '' }
                      showCrudResponseMessage({
                        error,
                        status,
                        entity,
                      })

                      if (!error) {
                        const newUser = {
                          ...currentUser,
                          devices: _.reject(currentUser.devices, {
                            deviceKey,
                          }),
                        }
                        setCurrentUser(newUser)
                      }
                    }}
                  >
                    Forget device
                  </Button>
                </div>
              </li>
            )
          }
        )
        .value()}
    </ul>
  )
}

export default TrustedDevices
