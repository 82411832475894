import { ReactElement, useCallback } from 'react'
import _ from 'lodash'
import { Form, Field } from 'react-final-form'

// constants
import { MFA_TYPES } from 'constants/common'

// utils
import { getUsernameFromEmail } from 'helpers/user'
import { validateRequiredKey } from 'helpers/validators'

// components
import { Button } from 'components/common'
import { Input, PhoneField } from 'components/common/Form'

// types
import type { User } from 'types/user'

import scss from '../../index.module.scss'

type Values = {
  givenName: string
  familyName: string
  email: string
  phone: string
}

const UserInformationForm = ({
  user,
  onChange,
}: {
  user: Partial<User>
  onChange: (v: Partial<User>) => void
}): ReactElement => {
  const { username, mfaPreferred } = user

  const onSubmit = useCallback(
    async (payload, form) => {
      const { dirtyFields } = form.getState()
      const validUsername = username || getUsernameFromEmail(payload.email)
      const newPayload = {
        ..._.pick(payload, _.keys(dirtyFields)),
        username: validUsername,
      }
      onChange(newPayload)
    },
    [username, onChange]
  )

  const validateForm = (values: Values) => {
    const errors = {} as { email?: string }
    validateRequiredKey('givenName', 'First Name')(values, errors)
    validateRequiredKey('familyName', 'Last Name')(values, errors)

    return errors
  }

  const renderUserInformation = ({
    handleSubmit,
    submitting,
    pristine,
    mfaPreferredValue,
  }: {
    handleSubmit: (v: Values) => void
    submitting: boolean
    pristine: boolean
    mfaPreferredValue: string
  }) => {
    const isCurrentUserSMSMfaEnabled = mfaPreferredValue === MFA_TYPES.SMS_MFA

    return (
      <form onSubmit={handleSubmit}>
        <div className='row g-3 mb-4'>
          <div className='col-md-6 col-sm-12'>
            <Field
              component={Input}
              name='givenName'
              label='First Name'
              placeholder='First Name'
              required
              labelClassName={scss.label}
            />
          </div>
          <div className='col-md-6 col-sm-12'>
            <Field
              labelClassName={scss.label}
              name='email'
              type='email'
              label='Email'
              component={Input}
              placeholder='Enter email'
              convertStringType='lowerCase'
              required
              readOnly
            />
          </div>
        </div>

        <div
          className={`row g-3 mb-2 ${
            isCurrentUserSMSMfaEnabled ? 'mb-2' : 'mb-4'
          }`}
        >
          <div className='col-md-6 col-sm-12'>
            <Field
              component={Input}
              name='familyName'
              label='Last Name'
              placeholder='Last Name'
              required
              labelClassName={scss.label}
              data-testid='last-name'
            />
          </div>
          <div className='col-md-6 col-sm-12'>
            <PhoneField
              labelClassName={scss.label}
              name='phone'
              label='Phone'
              readOnly={isCurrentUserSMSMfaEnabled}
            />

            {isCurrentUserSMSMfaEnabled && (
              <div className='smallText mt-2'>
                This phone number is registered to your 2-step verification and
                cannot be updated
              </div>
            )}
          </div>
        </div>

        <div className='row g-3 mb-2'>
          <div className='col-md-6 col-sm-12'>
            <Button
              type='submit'
              disabled={submitting || pristine}
              isLoading={submitting}
            >
              Update Profile
            </Button>
          </div>
        </div>
      </form>
    )
  }

  return (
    <Form
      initialValues={_.pick(user, [
        'givenName',
        'email',
        'familyName',
        'phone',
      ])}
      validate={validateForm}
      onSubmit={onSubmit}
      render={renderUserInformation}
      mfaPreferredValue={mfaPreferred}
    />
  )
}

export default UserInformationForm
