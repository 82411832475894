// libraries
import _ from 'lodash'
import { point as turfPoint } from '@turf/helpers'

// constants
import {
  CORRELATE_EVENT_TYPES,
  RESOLUTION_MODE_MAPPING,
} from 'app/MissionControlMethaneSolution/constants/detection'
import { USER_ESSENTIAL_FIELDS } from 'services/api/user'

// utils
import { getArgs, getFields, getQueryFields } from 'helpers/graphql'
import {
  listEntitiesGraphql,
  getEntitiesQuery,
  MutateEntity,
  mutateEntity,
} from 'services/api/utils'
import { getDetectionTypeLabel } from 'app/MissionControlMethaneSolution/helpers/detection'

import {
  Detection,
  SiteReference,
  Equipment,
  ReconciledDetection,
} from 'app/MissionControlMethaneSolution/types/graphql'
import type {
  DetectionData,
  DetectionSite,
} from 'app/MissionControlMethaneSolution/types/detection'
import type { QueryParams, SelectedFields } from 'types/services'
import type { Payload } from 'types/common'

import { SOURCE_ATTRIBUTION_FIELDS } from './emissionEvent'

export const DOMAIN = 'detection'
const queryDomain = `${DOMAIN}s`

export const EQUIPMENT_REFERENCE_FIELDS = {
  equipmentId: true,
  equipment: {
    id: true,
    name: true,
    description: true,
    components: {
      equipmentComponentName: true,
    },
  },
}

const clueFields = {
  data: true,
  id: true,
  images: {
    label: true,
    url: true,
  },
  type: true,
}

export const DETECTION_FIELDS = {
  id: true,
  shortId: true,
  acknowledged: true,
  detectedAt: true,
  emissionsRate: {
    value: true,
  },
  detectionType: true,
  inputType: true,
  detectionSource: true,
  sourceDescription: true,
  purpose: true,
  equipmentReference: EQUIPMENT_REFERENCE_FIELDS,
  component: {
    equipmentComponentName: true,
  },
  priority: true,
  screeningId: true,
  status: true,
  additionalData: true,
  media: {
    downloadUrl: true,
    mediaKey: true,
  },
  sourceAttribution: SOURCE_ATTRIBUTION_FIELDS,
  siteReference: {
    site: {
      id: true,
      properties: {
        name: true,
      },
      observations: true,
      outlier: true,
      outlierExplanation: true,
      deepDiveProperties: {
        underDeepDive: true,
      },
    },
  },
  resolution: {
    correlatedDetection: {
      detection: {
        shortId: true,
      },
    },
    correlatedClue: {
      clue: _.pick(clueFields, ['id', 'data', 'type']),
    },
    resolutionMode: true,
    explanation: true,
  },
  clues: {
    clueGroups: {
      clues: {
        clue: clueFields,
      },
      type: true,
    },
  },
  audit: {
    createdBy: {
      user: USER_ESSENTIAL_FIELDS,
    },
    updatedTime: true,
  },
  complianceDeadline: true,
  complianceSeverity: true,
  lastComplianceActionAt: true,
  correlatedEmissionEventCount: true,
  sourceLatitude: true,
  sourceLongitude: true,
}

export const getDetectionFields = getQueryFields(DETECTION_FIELDS)

export const COMMON_SITE_FIELDS = getFields(
  ['id', 'properties'],
  'siteReference.site'
)

const COMMON_DETECTION_FIELDS = [
  'id',
  'shortId',
  'detectedAt',
  'audit',
  'emissionsRate',
  'equipmentReference.equipmentId',
  'equipmentReference.equipment.description',
  'screeningId',
  'inputType',
  'detectionType',
  'priority',
  'component',
  'sourceLatitude',
  'sourceLongitude',
]

const COMMON_INBOX_DETECTION_FIELDS = [
  'acknowledged',
  ...COMMON_DETECTION_FIELDS,
  ...COMMON_SITE_FIELDS,
]

export const deserializeSite = (site: SiteReference['site']): DetectionSite => {
  const { properties, observations, outlier } = site ?? {}
  return {
    ...site,
    observations: _.map(observations, d => ({
      ...d,
      featureType: d.properties.entityType,
    })),
    isOutlier: outlier as boolean,
    name: properties?.name ?? '',
  }
}

export const deserializeDetection = (
  detection: Detection | ReconciledDetection
): DetectionData => {
  const {
    siteReference,
    equipmentReference,
    resolution,
    emissionsRate,
    complianceDeadline,
    complianceSeverity,
    audit,
    detectionType,
    component,
    priority,
    sourceLatitude,
    sourceLongitude,
  } = detection || {}
  const { site } = siteReference ?? {}
  const { equipmentId, equipment } = equipmentReference ?? {}
  const { description: equipmentDescription, components } = equipment ?? {}
  const { explanation, resolutionMode, correlatedDetection, correlatedClue } =
    resolution ?? {}
  const { updatedTime } = audit ?? {}
  const { equipmentComponentName } = component || {}

  const correlatedDetectionShortId = _.get(
    correlatedDetection,
    'detection.shortId'
  )

  const correlatedToType = correlatedDetection
    ? CORRELATE_EVENT_TYPES.WIP
    : !_.isEmpty(correlatedClue)
    ? CORRELATE_EVENT_TYPES.CLUE
    : undefined

  return {
    ...detection,
    site: deserializeSite(site),
    emissionsRate: emissionsRate?.value,
    ...(explanation && { explanation }),
    ...(resolutionMode && {
      resolutionMode: _.get(RESOLUTION_MODE_MAPPING, resolutionMode),
    }),
    equipment: equipmentDescription ?? equipmentId,
    components,
    ...(equipmentComponentName && { equipmentComponentName }),
    compliance: { complianceSeverity, complianceDeadline },
    lastUpdatedTime: updatedTime,
    correlatedTo: {
      type: correlatedToType,
      correlatedDetectionShortId,
      correlatedClue,
    },
    type: getDetectionTypeLabel(detectionType),
    ...(_.isNumber(sourceLongitude) &&
      _.isNumber(sourceLatitude) && {
        geoJsonFeature: turfPoint([sourceLongitude, sourceLatitude], {
          priority,
        }),
      }),
  }
}

const INBOX_DETECTION_VARIABLES = {
  first: 'Int',
  after: 'String',
  sortBy: 'EmissionEventSortBy',
  filter: 'InboxDetectionFilter',
}

const getDetectionsQuery = (
  queryName: string,
  variables: Payload<string> = INBOX_DETECTION_VARIABLES
) =>
  getEntitiesQuery<Detection>({
    queryDomain,
    queryName,
    variables,
    getFieldsFn: getDetectionFields,
  })

export const DETECTION_DEFAULT_OMIT_FIELDS = [
  'clues',
  'siteReference.site.observations',
]

const GET_DETECTIONS_COMMON_PROPS = {
  queryDomain,
  enableLoadMore: false,
  queryDisplayName: 'GetInboxInProgressDetections',
  defaultOmitFields: DETECTION_DEFAULT_OMIT_FIELDS,
  postProcessFn: deserializeDetection,
}

const WIP_CORRELATED_DETECTIONS_QUERY_NAME = 'byCorrelatedToDetectionId'

const WIP_CORRELATED_DETECTION_VARIABLES = {
  first: 'Int',
  after: 'String',
  correlatedToDetectionId: 'ID!',
}

export const getWIPDetectionCorrelatedDetections =
  listEntitiesGraphql<Detection>({
    ...GET_DETECTIONS_COMMON_PROPS,
    queryName: WIP_CORRELATED_DETECTIONS_QUERY_NAME,
    getQueryFn: getDetectionsQuery(
      WIP_CORRELATED_DETECTIONS_QUERY_NAME,
      WIP_CORRELATED_DETECTION_VARIABLES
    ),
    queryDisplayName: 'GetWIPCorrelatedDetections',
    defaultPickFields: [
      'id',
      'shortId',
      'detectedAt',
      'emissionsRate',
      'priority',
      'sourceLatitude',
      'sourceLongitude',
      'status',
    ],
  })

const mutateDetection =
  ({
    pickFields,
    omitFields,
    ...restProps
  }: Omit<MutateEntity, 'queryDomain'> & SelectedFields) =>
  ({
    id,
    fieldsWithArguments,
    ...rest
  }: { id?: string; fieldsWithArguments?: QueryParams } & QueryParams) =>
    mutateEntity<DetectionData>({
      queryDomain,
      responseFields: {
        [DOMAIN]: getDetectionFields({ pickFields, omitFields }),
      },
      responsePath: [DOMAIN],
      withIdentifier: false,
      ignoreError: true,
      postProcessFn: deserializeDetection,
      ...restProps,
    })(id, rest, fieldsWithArguments) as Promise<{
      data: DetectionData
      error?: string
    }>

export const createDetection = mutateDetection({
  fnName: 'createDetection',
  variableFormat: 'CreateDetectionInput!',
  pickFields: COMMON_INBOX_DETECTION_FIELDS,
})

export const createInspectionRequest = mutateDetection({
  fnName: 'createInspectionRequest',
  responseFields: {},
  variableFormat: 'CreateInspectionRequestInput!',
})

export const resolveDetection = mutateDetection({
  fnName: 'resolveDetection',
  responseFields: {},
  variableFormat: 'ResolveDetectionInput!',
})

export const getMediaUploadUrlsQuery = (
  files: File[]
): Record<string, unknown> => {
  const mediaKeys = _.map(files, 'name')
  return {
    [DOMAIN]: {
      mediaUploadUrls: {
        ...getArgs({ mediaKeys }),
      },
    },
  }
}

const siteEquipmentQueryDomain = 'equipment'
const siteEquipmentQueryName = 'bySite'
export const getSiteEquipments = listEntitiesGraphql<Equipment>({
  queryDomain: siteEquipmentQueryDomain,
  getQueryFn: getEntitiesQuery({
    queryDomain: siteEquipmentQueryDomain,
    getFieldsFn: getQueryFields({
      id: true,
      description: true,
      components: {
        equipmentComponentName: true,
      },
    }),
    variables: {
      siteId: 'ID!',
    },
    queryName: siteEquipmentQueryName,
  }),
  queryName: siteEquipmentQueryName,
})
