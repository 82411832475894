import { useMemo, useCallback } from 'react'
import moment from 'moment-timezone'
import { UTC_ABBR } from 'constants/datetime'
import { useAuthStateValue } from 'contexts'
import { getUserEnableTimezone } from 'helpers/user'

import type { Timezone, UtcISOString } from 'types/datetime'

/**
 * The central place to format time with timezone
 */
const useTimezone = (): {
  timezone: Timezone
  timezoneAbbr: string
  getUtcOffset: (targetTime: UtcISOString) => void
} => {
  const {
    currentUser: { preferences, timezone = UTC_ABBR },
  } = useAuthStateValue()

  const enableTimezone = useMemo(
    () => getUserEnableTimezone({ preferences }),
    [preferences]
  )

  const result = useMemo(
    () => (enableTimezone ? timezone : undefined),
    [enableTimezone, timezone]
  )

  const getUtcOffset = useCallback(
    (targetTime: UtcISOString) =>
      enableTimezone
        ? moment.utc(targetTime).tz(timezone).format('Z')
        : '-00:00',
    [enableTimezone, timezone]
  )

  const timezoneAbbr = useMemo(
    () => (enableTimezone ? moment.tz(timezone).zoneAbbr() : UTC_ABBR),
    [enableTimezone, timezone]
  )

  return { timezone: result, timezoneAbbr, getUtcOffset }
}

export default useTimezone
