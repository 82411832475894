// libraries
import { useCallback } from 'react'
import isEqual from 'fast-deep-equal'
import _ from 'lodash'

// constants
import { DEFAULT_IDENTITY_PROPERTY } from 'constants/common'
import { DEFAULT_PROFILE_PROPERTIES_SETTING } from 'constants/profile'

// utils
import { useStateValue, useMapStateValue } from 'contexts'
import { removeNonExistingLayerPopups } from 'components/map/controls/MapPopup/mapPopupUtil'
import {
  getFeatureLatestProperties,
  getLayerProfileTitle,
  isAssetLayer,
} from 'helpers/map'
import { displayValue } from 'helpers/utils'
import {
  getDatasetPropertyMetadata,
  getIdentityPropertyValue,
} from 'helpers/unipipe'
import { useTimezone } from 'hooks'

import type { MapLayer, GeojsonData } from 'types/map'
import type { Payload } from 'types/common'

const useMapClickedFeatures = (): {
  updateMapClickedFeatures: (layers: MapLayer[]) => void
  getFeatureWithProfileSetting: ({
    layerId,
    object,
  }: {
    layerId: string
    object: GeojsonData
  }) => void
  getClusteringPointsDisplayedValues: ({
    layerId,
    object,
  }: {
    layerId: string
    object: Payload[]
  }) => void
} => {
  const {
    state: {
      unipipeState: { datasetServices },
    },
    selectors: {
      unipipeSelectors: { pickedDatasetsMetadata },
    },
  } = useStateValue()
  const { timezone } = useTimezone()

  const { getLayer, getMapClickedFeatures, setMapClickedFeatures } =
    useMapStateValue()

  const getFeatureWithProfileSetting = useCallback(
    ({ layerId, object }: { layerId: string; object: GeojsonData }) => {
      const mapLayer = getLayer(layerId)
      if (!mapLayer) return {}

      const {
        dataset,
        profile: {
          type: profileType,
          title: profileTitle,
          properties: profileProperties,
        },
      } = mapLayer

      const { identityProperty = DEFAULT_IDENTITY_PROPERTY } =
        pickedDatasetsMetadata[dataset] || {}

      const { properties, id, displayName } = object || {}

      const identityValue = isAssetLayer(mapLayer)
        ? id
        : getIdentityPropertyValue(object, identityProperty)

      const title = isAssetLayer(mapLayer)
        ? displayName
        : properties && profileTitle
        ? displayValue(properties[profileTitle], timezone, false)
        : displayValue(identityValue)

      const propertiesOptions = getDatasetPropertyMetadata(
        mapLayer,
        datasetServices
      )

      return (
        propertiesOptions && {
          name: identityValue,
          title,
          profileProperties,
          dataset,
          datasetServices,
          properties,
          propertiesOptions,
          profileType,
          mapLayer,
        }
      )
    },
    [pickedDatasetsMetadata, datasetServices, getLayer, timezone]
  )

  const getClusteringPointsDisplayedValues = useCallback(
    ({ layerId, object }: { layerId: string; object: Payload[] }) => {
      const mapLayer = getLayer(layerId)
      if (!mapLayer) return {}

      const { dataset, name: layerName } = mapLayer
      const profileTitle = getLayerProfileTitle(mapLayer)

      const { properties } = pickedDatasetsMetadata[dataset] || {}
      const propertiesKeyByValue = _.keyBy(properties, 'value')
      // src/components/map/layers/deckLayers/pointLayer/scatterplotClusterLayer.js
      // getPickingInfo
      return {
        properties: _.map(object, ({ value, name, key }) => ({
          key,
          value,
          label:
            _.get(propertiesKeyByValue[name], 'displayName') ||
            _.startCase(name),
          ...DEFAULT_PROFILE_PROPERTIES_SETTING,
        })),
        layerName: profileTitle
          ? _.get(propertiesKeyByValue[profileTitle], 'displayName')
          : layerName,
      }
    },
    [pickedDatasetsMetadata, getLayer]
  )

  const updateMapClickedFeatures = useCallback(
    (layers: MapLayer[]) => {
      const mapClickedFeatures = getMapClickedFeatures()
      const validMapClickedFeatures = removeNonExistingLayerPopups(
        layers,
        mapClickedFeatures
      )

      const newMapClickedFeatures = validMapClickedFeatures.map(
        mapClickedFeature => {
          const feature = getFeatureWithProfileSetting(mapClickedFeature)
          const latestFeatureProperties = getFeatureLatestProperties(
            feature,
            mapClickedFeature
          )
          return {
            ...mapClickedFeature,
            ...latestFeatureProperties,
          }
        }
      )

      if (isEqual(newMapClickedFeatures, mapClickedFeatures)) return

      setMapClickedFeatures(newMapClickedFeatures)
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      getMapClickedFeatures(),
      getFeatureWithProfileSetting,
      setMapClickedFeatures,
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  )

  return {
    updateMapClickedFeatures,
    getFeatureWithProfileSetting,
    getClusteringPointsDisplayedValues,
  }
}

export default useMapClickedFeatures
