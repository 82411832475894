import { useMemo } from 'react'

import { convertAndFormatEmissionRate } from 'app/MissionControlMethaneSolution/helpers/detection'

import { useDetectionGalleryStateValue } from 'app/MissionControlMethaneSolution/contexts'

/** Converts 'emissionsRate' to kg/h or scf/h depending on a saved settings */
const useEmissionRateUnitConversion = (
  emissionsRate?: number
): {
  emissionRateWithUnit: string
  emissionRateValue: string
  emissionRateUnitLabel: string
} => {
  const { measurementUnit } = useDetectionGalleryStateValue()

  return useMemo(() => {
    const emissionRateWithUnit = convertAndFormatEmissionRate(
      emissionsRate,
      measurementUnit
    )
    const [emissionRateValue, emissionRateUnitLabel] =
      emissionRateWithUnit.split(' ')
    return { emissionRateWithUnit, emissionRateValue, emissionRateUnitLabel }
  }, [emissionsRate, measurementUnit])
}

export default useEmissionRateUnitConversion
