// @flow

// libraries
import React, { useMemo } from 'react'
import { MdAccessTime } from 'react-icons/md'

// utils
import { displayTime } from 'helpers/utils'

// constants
import { getTimeFormatter } from 'constants/datetime'

import scss from '../../index.module.scss'

type Props = {
  selectedDateTimeRange: { start: string, end: string },
  intervalUnit: string,
  timezone?: string,
  timezoneAbbr?: string,
}

const TimeDisplay = ({
  selectedDateTimeRange,
  intervalUnit,
  timezone,
  timezoneAbbr,
}: Props) => {
  const { start, end } = selectedDateTimeRange
  const timeFormat = useMemo(
    () => getTimeFormatter(intervalUnit),
    [intervalUnit]
  )

  const startDisplayTime = useMemo(
    () =>
      displayTime({
        datetime: start,
        timezone,
        addFromNow: false,
        timeFormat,
        displayLabel: false,
      }),
    [start, timeFormat, timezone]
  )

  const endDisplayTime = useMemo(
    () =>
      displayTime({
        datetime: end,
        timezone,
        addFromNow: false,
        timeFormat,
        displayLabel: false,
      }),
    [end, timeFormat, timezone]
  )

  return (
    <div>
      <MdAccessTime style={{ color: 'white' }} />
      <span className={scss.dateTimeText}>
        {timezoneAbbr}: <span className='ms-1'>{startDisplayTime}</span> -
        <span className='ms-1'>{endDisplayTime}</span>
      </span>
    </div>
  )
}

TimeDisplay.defaultProps = {
  timezone: undefined,
  timezoneAbbr: undefined,
}

export default TimeDisplay
