// libraries
import { FC, useCallback, useEffect, useRef, useState } from 'react'

// components
import { AdvanceTooltip } from 'components/common/Tooltip'

import type { AdvanceTooltipProps } from 'components/common/Tooltip/types'

type TruncatingTextWithTooltipProps = AdvanceTooltipProps & {
  className?: string
}

export const TruncatingTextWithTooltip: FC<TruncatingTextWithTooltipProps> = ({
  title,
  children,
  className = '',
  ...rest
}) => {
  const textRef = useRef<HTMLDivElement>(null)
  const [isTextOverflowing, setIsTextOverflowing] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const checkTextOverflow = useCallback(() => {
    const { current } = textRef
    if (current) {
      const isOverflowing = current.scrollWidth > current.clientWidth
      setIsTextOverflowing(isOverflowing)
    }
  }, [])

  useEffect(() => {
    const currentElement = textRef.current
    const resizeObserver = new ResizeObserver(checkTextOverflow)

    if (currentElement) {
      resizeObserver.observe(currentElement)
    }

    return () => {
      if (currentElement) {
        resizeObserver.unobserve(currentElement)
      }
      resizeObserver.disconnect()
    }
  }, [checkTextOverflow])

  const handleMouseEnter = useCallback(() => setIsHovered(true), [])
  const handleMouseLeave = useCallback(() => setIsHovered(false), [])

  const visible = isTextOverflowing && isHovered

  return (
    <div
      className={`overflow-hidden ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <AdvanceTooltip {...rest} visible={visible} title={title}>
        <div ref={textRef} className='text-truncate'>
          {children}
        </div>
      </AdvanceTooltip>
    </div>
  )
}
