// libraries
import { useState, useEffect, useCallback, ReactElement, FC } from 'react'
import _ from 'lodash'

// utils
import { useRangeSliderStyle } from 'hooks'

// components
import { NumericInput } from 'components/common'
import TooltipSlider from 'components/common/RangeSlider/TooltipSlider'

import type { RangeSliderProps } from '../types'

export const Range: FC<RangeSliderProps> = ({
  rangeValue, // current positions of handles (used for point layer 'radius based on property')
  defaultRangeValue, // default value for range, initial positions of handles. (used for point layer 'radius based on property')
  onChange,
  className,
  isLarge,
  showDots,
  hideInput,
  ...rest
}): ReactElement => {
  const styleOverride = useRangeSliderStyle({
    type: 'range',
    isLarge,
    showDots,
  })

  const [max, setMax] = useState(() =>
    Math.max(rangeValue[1], defaultRangeValue[1])
  )

  const handlerMax = useCallback(
    value => {
      const newRange = [rangeValue[0], value]
      setMax(value)
      onChange(newRange)
    },
    [onChange, rangeValue]
  )

  useEffect(() => {
    const newMax = Math.max(rangeValue[1], max)
    setMax(newMax)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeValue])

  return (
    <div className={`row g-3 ${className}`}>
      <div className={hideInput ? 'col-12' : 'col-8'}>
        <TooltipSlider
          {...styleOverride}
          {..._.omit(rest, ['value'])}
          allowCross={false}
          hideInput={hideInput}
          min={defaultRangeValue[0]}
          max={max}
          defaultValue={rangeValue}
          onAfterChange={onChange}
          range
        />
      </div>
      {!hideInput && (
        <div className='col-4'>
          <NumericInput
            value={max}
            className='form-control rangeSliderInput'
            onChange={handlerMax}
            step={1}
            testId='range-number'
          />
        </div>
      )}
    </div>
  )
}

Range.defaultProps = {
  rangeValue: [],
  defaultRangeValue: [],
  className: '',
  hideInput: true,
}

export default Range
