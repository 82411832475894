import { useCallback } from 'react'

// type
import type { UseListProps, UseListState } from 'types/common'
import type { DetectionData } from 'app/MissionControlMethaneSolution/types/detection'

// constants
import { EMISSION_RATE_UNIT_OPTIONS } from 'app/MissionControlMethaneSolution/constants/detection'

// components
import { SwitchUnits } from 'components/common'

const useDetectionAdditionalTools = ({
  measurementUnit,
  setListConditions,
}: {
  measurementUnit?: string
  setListConditions: UseListProps<DetectionData>['setListConditions']
}): {
  renderAdditionalTools: UseListState<DetectionData>['renderAdditionalTools']
} => {
  const renderAdditionalTools = useCallback(
    () => (
      <SwitchUnits
        options={EMISSION_RATE_UNIT_OPTIONS}
        selectedValue={measurementUnit as string}
        onChange={newUnit =>
          setListConditions(prevState => ({
            ...prevState,
            measurementUnit: newUnit,
          }))
        }
      />
    ),
    [measurementUnit, setListConditions]
  )

  return { renderAdditionalTools }
}

export default useDetectionAdditionalTools
