import { useMemo } from 'react'

import { EMISSION_EVENT_ATTRIBUTION_TYPES } from 'app/MissionControlMethaneSolution/constants/emissionEvent'
import type { DetectionData } from 'app/MissionControlMethaneSolution/types/detection'

const useSourceAttributionName = ({
  sourceAttribution,
  equipmentReference,
  site,
}: Pick<DetectionData, 'sourceAttribution' | 'site' | 'equipmentReference'>): {
  isAttributed: boolean
  attributionName?: string | null
  attributionId?: string
} => {
  const { equipmentId, equipment } = equipmentReference || {}

  const isAttributed = !!sourceAttribution
  const isAttributedToEquipment =
    sourceAttribution?.attributionType ===
    EMISSION_EVENT_ATTRIBUTION_TYPES.EQUIPMENT

  const attributionName = useMemo(() => {
    if (!sourceAttribution) return ''
    return isAttributedToEquipment ? equipment?.description : site.name
  }, [sourceAttribution, isAttributedToEquipment, site, equipment])

  return {
    isAttributed,
    attributionName,
    attributionId: isAttributedToEquipment ? equipmentId : site.id,
  }
}

export default useSourceAttributionName
