// libraries
import { useToggle } from 'react-use'

// constants
import { APP_URLS } from 'app/MissionControlMethaneSolution/constants/common'

// utils
import { getRouteUrlWithValues } from 'helpers/utils'

import type { IconButtonProps } from 'components/common/IconButton'

const useDetectionLink = (
  detectionId?: string
): { linkButton: IconButtonProps; link: string } => {
  const [isCopied, toggleIsCopied] = useToggle(false)

  const link = getRouteUrlWithValues(
    `${window.location.origin}${process.env.PUBLIC_URL}${APP_URLS.EMISSIONS}`,
    { id: detectionId }
  )

  const linkButton: IconButtonProps = isCopied
    ? {
        icon: 'AiFillCheckCircle',
        size: 18,
        label: 'Copied',
        key: detectionId,
      }
    : {
        icon: 'LinkIcon',
        onClick: () => {
          navigator.clipboard.writeText(link)

          toggleIsCopied(true)
          setTimeout(() => {
            toggleIsCopied(false)
          }, 1000)
        },
        size: 18,
        label: 'Copy link',
        key: detectionId,
      }

  return { linkButton, link }
}

export default useDetectionLink
